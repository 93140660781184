<template>
  <Widget v-if="reportsComputed.length > 0" :title="$t('Berichte')">
    <div v-if="!companyClusterActive">
      <div v-for="report in reportsComputed" class="widget-reports__report" :key="report.key">
        <h3>{{ report.title }}</h3>
        <p
          class="widget-reports__report-description"
          :class="{ 'widget-reports__report-description--blurred': !report.enabled }"
        >
          {{ report.description }}
        </p>
        <ButtonLink v-if="report.enabled" target="_blank" :href="report.link">
          {{ $t('Zum Bericht') }}
        </ButtonLink>
        <ButtonLink v-else :to="{ name: 'shop' }">
          {{ $t('Jetzt Lizenz upgraden') }}
        </ButtonLink>
      </div>
    </div>
    <div v-else>
      <div class="widget-reports__report">
        <p class="widget-reports__report-description">
          {{ $t('Wähle einen Betrieb um deine Berichte anzuzeigen.') }}
        </p>
        <ButtonLink :to="{ name: 'reports' }">
          {{ $t('Zu den Berichten') }}
        </ButtonLink>
      </div>
    </div>
  </Widget>
</template>

<script>
import Widget from '@/shared/components/Widget.vue';
import ButtonLink from '@/shared/components/buttons/ButtonLink.vue';
import { LICENSE_PACKAGE_GUIDS, REGION_GUIDS, origin } from '@/shared/constants';

export default {
  name: 'WidgetReports',
  components: { ButtonLink, Widget },
  data() {
    return {
      reports: {
        at: [
          {
            id: 32,
            title: this.$t('Feld-Übersicht'),
            description: this.$t('Liste aller Feldgruppen und Felder im gewählten Erntejahr.'),
            availableInFreeLicense: true,
          },
          {
            id: 1,
            title: this.$t('Feldstücksliste'),
            description: this.$t('Liste aller Feldstücke und Schläge im gewählten Erntejahr.'),
            availableInFreeLicense: false,
          },
          {
            id: 27,
            title: this.$t('Kulturen'),
            description: this.$t('Liste aller Felder gruppiert nach Kulturen.'),
            availableInFreeLicense: false,
          },
          {
            id: 24,
            title: this.$t('Kulturen & Greening-Kennzahlen'),
            description: this.$t('Kulturen, Feldstücknutzung, Anbaudiversivizierung und ökologische Vorrangflächen.'),
            availableInFreeLicense: false,
          },
          {
            id: 49,
            title: this.$t('UBB'),
            description: this.$t('Umweltgerechte und biodiversitätsfördernde Bewirtschaftung.'),
            availableInFreeLicense: false,
          },
        ],
        international: [
          {
            id: 32,
            title: this.$t('Feld-Übersicht'),
            description: this.$t('Liste aller Feldgruppen und Felder im gewählten Erntejahr.'),
            availableInFreeLicense: true,
          },
          {
            id: 27,
            title: this.$t('Kulturen'),
            description: this.$t('Liste aller Felder gruppiert nach Kulturen.'),
            availableInFreeLicense: false,
          },
        ],
      },
    };
  },
  computed: {
    companyClusterActive() {
      return this.$store.state.auth.currentCompanies.length > 1;
    },
    useAtReports() {
      return this.$store.state.auth.currentCompanies.some((company) => company.regionId === REGION_GUIDS.AT);
    },
    reportsComputed() {
      let reportKey = 'international';
      if (this.useAtReports) {
        reportKey = 'at';
      }
      return this.reports[reportKey].map((report) => ({
        ...report,
        key: `report_${report.id}`,
        link: `${origin}/admin/reports/preview/${report.id}`,
        enabled: report.availableInFreeLicense || this.reportShouldBeEnabled,
      }));
    },
    companyHasFreeLicense() {
      return this.$store.state.auth.currentCompanies.some(
        (company) => company.licensePackageId === LICENSE_PACKAGE_GUIDS.FARMDOK_FREE,
      );
    },
    companyHasNoLicense() {
      return this.$store.state.auth.currentCompanies.some(
        (company) => company.licensePackageId === LICENSE_PACKAGE_GUIDS.NO_LICENSE,
      );
    },
    companyHasPrecisionLicense() {
      return this.$store.state.auth.currentCompanies.some(
        (company) => company.licensePackageId === LICENSE_PACKAGE_GUIDS.PRECISION,
      );
    },
    reportShouldBeEnabled() {
      return !(this.companyHasPrecisionLicense || this.companyHasNoLicense || this.companyHasFreeLicense);
    },
  },
};
</script>

<style scoped>
.widget-reports__report {
  margin-bottom: var(--spacer_1);
  padding: var(--spacer_3);
  background: var(--lightest);
  font-size: 12px;
}

.widget-reports__report-description {
  font-style: italic;
}

.widget-reports__report-description--blurred {
  filter: blur(4px);
  user-select: none;
}
</style>
