import { createUuid } from '@/shared/modules/uuid';
export default class ActivityService {
    constructor(activities, fieldService, activityEquipmentService, activityProductService, companyService) {
        this.activities = activities;
        this.fieldService = fieldService;
        this.activityEquipmentService = activityEquipmentService;
        this.activityProductService = activityProductService;
        this.companyService = companyService;
    }
    static createActivity(id) {
        return {
            id: id !== null && id !== void 0 ? id : createUuid(),
            deleted: null,
            tstamp: 0,
            userId: null,
            processOrderId: '',
            trackId: null,
            activityTypeId: '',
            fieldId: null,
            userComment: null,
            timeStart: null,
            files: [],
            timeFinish: null,
            creationDate: null,
            processedArea: null,
            workingWidth: null,
            workingTime: null,
            drivingTime: null,
            setupTime: null,
            pauseTime: null,
            isCopy: false,
            processingState: null,
            completed: false,
            products: [],
            equipment: [],
        };
    }
    // TODO rename to findActivity and return Activity | undefined
    getActivity(activityId) {
        const activity = this.activities[activityId];
        return activity !== null && activity !== void 0 ? activity : null;
    }
    getCropOfActivity(activityId) {
        const activity = this.activities[activityId];
        if (!activity)
            throw new Error(`Activity with id ${activityId} not found`);
        const { fieldId } = activity;
        if (!fieldId)
            return null;
        return this.fieldService.getCropOfField(fieldId);
    }
    getCompanyOfActivity(activityId) {
        const activity = this.getActivity(activityId);
        if (!activity)
            throw new Error(`Activity with id ${activityId} not found`);
        return this.companyService.getCompanyByProcessOrder(activity.processOrderId);
    }
    findActivityEquipmentsOrSiblings(activity, referenceActivityEquipment) {
        return activity.equipment.filter((e) => this.activityEquipmentService.isSibling(e, referenceActivityEquipment));
    }
    findActivityProductsOrSiblings(activity, referenceActivityProduct) {
        return activity.products.filter((p) => this.activityProductService.isSibling(p, referenceActivityProduct));
    }
    //
    // ENTITY FUNCTIONS START
    // the following functions should be part of Activity Entity
    //
    static findActivityEquipmentsById(activity, activityEquipmentIds) {
        return activity.equipment.filter((activityEquipment) => activityEquipmentIds.includes(activityEquipment.id));
    }
    addActivityEquipment(activity, activityEquipment) {
        if (!this.isCompatibleActivityEquipment(activity, activityEquipment)) {
            throw new Error(`Activity equipment ${activityEquipment.id} is not compatible with activity`);
        }
        activity.equipment.push(activityEquipment);
    }
    addActivityProduct(activity, activityProduct) {
        if (!this.isCompatibleActivityProduct(activity, activityProduct)) {
            throw new Error(`Activity product ${activityProduct.id} is not compatible with activity`);
        }
        activity.products.push(activityProduct);
    }
    isCompatibleActivityEquipment(activity, activityEquipment) {
        const companyIdActivityEquipment = this.activityEquipmentService.getCompanyId(activityEquipment);
        if (!companyIdActivityEquipment)
            return true;
        const companyId = this.companyService.getCompanyByProcessOrder(activity.processOrderId).id;
        if (companyId !== null && companyId !== companyIdActivityEquipment) {
            return false;
        }
        return true;
    }
    isCompatibleActivityProduct(activity, activityProduct) {
        const companyIdActivityProduct = this.activityProductService.getCompanyId(activityProduct);
        if (!companyIdActivityProduct)
            return true;
        const companyId = this.companyService.getCompanyByProcessOrder(activity.processOrderId).id;
        if (companyId !== null && companyId !== companyIdActivityProduct) {
            return false;
        }
        return true;
    }
}
