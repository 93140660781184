
import { HotTable } from '@handsontable/vue';
import Handsontable from 'handsontable';
import _cloneDeep from 'lodash.clonedeep';
import _debounce from 'lodash.debounce';
import _isEqual from 'lodash.isequal';
import Vue, { PropType, defineComponent } from 'vue';

import { TableDataAssessment } from '@/datura/assessments/handsontable/types';
import { HOT_LICENSE_KEY } from '@/shared/constants';
import TableHeaderContextMenuInjector from '@/shared/handsontable/components/TableHeaderContextMenuInjector.vue';
import TableWrapper from '@/shared/handsontable/components/TableWrapper.vue';
import optionalCheckboxHooks from '@/shared/handsontable/renderers/optionalCheckboxRenderer/hooks';
import addIdsToChanges from '@/shared/handsontable/rework/addIdsToChanges';
import collapsedSubtableHooks from '@/shared/handsontable/rework/cellTypes/collapsedSubtableRenderer/hooks';
import { expandHooks } from '@/shared/handsontable/rework/cellTypes/expandRenderer';
import optionalRendererHooks from '@/shared/handsontable/rework/cellTypes/optionalRenderer/hooks';
import { settings as colHeadersSettings } from '@/shared/handsontable/rework/features/colHeaders';
import {
  colHeadersHook as colHeadersSelectHook,
  hooks as colHeadersSelectHooks,
} from '@/shared/handsontable/rework/features/colHeaders/colHeadersHooks/colHeadersSelect';
import { colHeadersHook as colHeadersTitleHook } from '@/shared/handsontable/rework/features/colHeaders/colHeadersHooks/colHeadersTitle';
import { hooks as colspanHooks } from '@/shared/handsontable/rework/features/colspan';
import createInitialSortConfig from '@/shared/handsontable/rework/features/columnSorting/createInitialSortConfig';
import detectChanges from '@/shared/handsontable/rework/features/detectChanges';
import { RowPropChange } from '@/shared/handsontable/rework/features/detectChanges/types';
import { filterTable, resetFilter } from '@/shared/handsontable/rework/features/filter';
import FilterInfoBarInjector from '@/shared/handsontable/rework/features/filter/FilterInfoBarInjector.vue';
import { FilterByColumnKey, FilterByDate } from '@/shared/handsontable/rework/features/filter/types';
import hiddenColumnSettings from '@/shared/handsontable/rework/features/hiddenColumns/settings';
import removeChangesToSameValues from '@/shared/handsontable/rework/features/hooks/removeChangesToSameValues';
import keyboardNavigationHooks from '@/shared/handsontable/rework/features/keyboardNavigation/hooks';
import manualColumnMoveHooks from '@/shared/handsontable/rework/features/manualColumnMove/hooks';
import { hooks as manualColumnResizeHooks } from '@/shared/handsontable/rework/features/manualColumnResize';
import {
  hooks as nestedTableHooks,
  settings as nestedTableSettings,
} from '@/shared/handsontable/rework/features/nestedTable';
import { ColumnSettingsFlattenedSubtable } from '@/shared/handsontable/rework/features/nestedTable/types';
import { hooks as placeholderColumnHooks } from '@/shared/handsontable/rework/features/placeholderColumn';
import rowBackgroundStoreStatusHooks from '@/shared/handsontable/rework/features/rowBackgroundStoreStatus/hooks';
import { ColumnSettingsWithUserSettings } from '@/shared/handsontable/rework/types';
import { CellCoords } from '@/shared/handsontable/types';
import notNullOrUndefined from '@/shared/modules/notNullOrUndefinedFilter';

enum FarmdokChangeSource {
  populateFromTableDataChange = 'populateFromTableDataChange',
}

export default defineComponent({
  name: 'TableAssessments',
  components: { HotTable, TableWrapper, TableHeaderContextMenuInjector, FilterInfoBarInjector },
  props: {
    tableData: {
      type: Array as PropType<TableDataAssessment[]>,
      required: true,
    },
    columns: {
      type: Array as PropType<ColumnSettingsWithUserSettings[]>,
      required: true,
    },
    fixedColumnsLeft: { type: Number, default: 0 },
    filterString: { type: String, default: '' },
  },
  data(): {
    initialTableData: TableDataAssessment[];
    initialTableSettings: Handsontable.GridSettings;
    hot: Handsontable | null;
    filtersByColumnKey: Record<string, FilterByColumnKey>;
    filtersByDate: FilterByDate | null;

    debouncedFilterActive: boolean;
    tableId: string;
  } {
    return {
      initialTableData: [], // will be initialized in created()
      initialTableSettings: {}, // will be initialized in created()
      hot: null, // will be initialized in mounted()
      filtersByColumnKey: {},
      filtersByDate: null,
      debouncedFilterActive: false,
      tableId: 'table-assessments',
    };
  },
  created() {
    this.initialTableData = _cloneDeep(this.tableData);
    this.initialTableSettings = _cloneDeep(this.createTableSettings(this.initialTableData, this.columns));

    // @ts-ignore
    this.unwatchFilterString = this.$watch('filterWatchTrigger', _debounce(this.filterWatchHandler, 200));
    // @ts-ignore
    this.unwatchFilterActive = this.$watch(
      'filterActive',
      _debounce((newValue: boolean) => {
        this.debouncedFilterActive = newValue;
      }, 200),
    );
  },
  mounted() {
    // @ts-ignore
    this.hot = this.$refs.table.hotInstance;

    if (!this.hasPersistentStateColumnSorting()) {
      this.applyInitialSortConfig();
    }

    this.validatePersistentState(this.tableColumns);
    this.restorePersistentState();
  },
  beforeDestroy() {
    this.hot = null;
    // @ts-ignore
    this.unwatchFilterString();
    // @ts-ignore
    this.unwatchFilterActive();
  },
  computed: {
    tableColumns(): ColumnSettingsFlattenedSubtable[] {
      if (!this.hot) return [];
      const { columns } = this.hot.getSettings();
      if (!columns) return [];
      if (!Array.isArray(columns)) throw new Error('columns must be of type Array');

      return columns;
    },
    filterActive(): boolean {
      const filtersByColumnKeyActive = Object.keys(this.filtersByColumnKey).length > 0;
      return this.filterString !== '' || filtersByColumnKeyActive || this.filtersByDate !== null;
    },
    filterWatchTrigger(): string {
      return `${this.filterString}${JSON.stringify(this.filtersByColumnKey)}${this.filterActive}${
        this.tableData
      }${JSON.stringify(this.filtersByDate)}`;
    },
    //
    // "public" computed props that can also be called from outside the component
    //
    visibleRowsIds(): Set<string> {
      const uniqueIds = new Set(this.hot?.getDataAtProp('id') as unknown as string);
      return uniqueIds;
    },
    visibleRows(): number {
      return this.visibleRowsIds.size;
    },
    totalRows(): number {
      return this.tableData.length;
    },
    allRowsVisible(): boolean {
      return this.visibleRows === this.totalRows;
    },
    groupedTableDataIds(): Set<string> {
      const uniqueIds: Set<string> = new Set();
      this.tableData.forEach((data) => {
        uniqueIds.add(data.id);
      });
      return uniqueIds;
    },
    expandedTableData(): TableDataAssessment[] {
      return this.tableData.filter(() => true);
    },
  },
  watch: {
    columns: {
      handler(newColumns: ColumnSettingsWithUserSettings[]) {
        if (!this.hot) return;

        // if (_isEqual(newTableSettings, oldTableSettings)) return;
        const { colHeaders, columns, hiddenColumns } = this.tableSettingsThatDependOnColumns(newColumns);
        this.updateSettings({
          colHeaders,
          columns,
          hiddenColumns,
        });

        this.moveColumnsByVisualColumn(columns);

        this.hot.render();

        this.validatePersistentState(columns);
        this.updatePersistentState(columns);
      },
    },
    tableData: {
      handler(newTableData: TableDataAssessment[], oldTableData: TableDataAssessment[]) {
        if (this.haveToLoadCompleteTableData(newTableData, oldTableData)) {
          this.loadTableData(newTableData);
        } else {
          this.detectCellChangesAndUpdateCells(newTableData, oldTableData);
        }
      },
    },
    hot: {
      handler(newHot: Handsontable | null) {
        this.$emit('update:hot', newHot);
      },
    },
  },
  methods: {
    haveToLoadCompleteTableData(newTableData: TableDataAssessment[], oldTableData: TableDataAssessment[]): boolean {
      return newTableData.length !== oldTableData.length;
    },
    loadTableData(newTableData: TableDataAssessment[]): void {
      if (!this.hot || !this.tableColumns.length) return;
      const settingsWithData = {
        ...this.tableSettingsThatDependOnTableData(newTableData),
        data: newTableData,
      };
      this.updateSettings(settingsWithData);
    },
    detectCellChangesAndUpdateCells(newTableData: TableDataAssessment[], oldTableData: TableDataAssessment[]): void {
      if (!this.hot || !this.tableColumns.length) return;
      const changes = detectChanges(oldTableData, newTableData, this.tableColumns, this.hot);
      this.updateCells(changes);
    },
    updateSettings(newSettings: Handsontable.GridSettings): void {
      if (!this.hot) return;
      const currentSortConfig = this.getCurrentSortConfig(); // we need to save and apply the current sort config, because the updateSettings will reset it
      this.hot.updateSettings(newSettings);
      this.applySortConfig(currentSortConfig?.[0]);
    },
    updateCells(changes: RowPropChange[]): void {
      if (changes.length === 0) return;
      this.hot?.setDataAtRowProp(changes, FarmdokChangeSource.populateFromTableDataChange);
    },
    moveColumnsByVisualColumn(columns: ColumnSettingsFlattenedSubtable[]): void {
      if (!this.hot) return;
      const moveColumns = this.getMoveColumns(columns);
      this.hot.getPlugin('manualColumnMove').moveColumns(moveColumns, this.fixedColumnsLeft);
    },
    getMoveColumns(columns: ColumnSettingsFlattenedSubtable[]) {
      const columnsSorted = [...columns];
      columnsSorted.sort((a, b) => a.visualColumn - b.visualColumn);
      const moveColumns = columnsSorted.map((column) => column.physicalColumn);
      const moveColumnsWithoutFixedColumns = moveColumns.slice(this.fixedColumnsLeft);
      return moveColumnsWithoutFixedColumns;
    },
    updatePersistentState(columns: ColumnSettingsFlattenedSubtable[]) {
      if (!this.hot) return;
      const persistentStatePlugin = this.hot.getPlugin('persistentState');
      if (!persistentStatePlugin.isEnabled()) return;

      this.updatePersistentStateManualColumnMove(columns, persistentStatePlugin);
      this.updatePersistentStateHiddenColumns(persistentStatePlugin);
    },
    updatePersistentStateManualColumnMove(
      columns: ColumnSettingsFlattenedSubtable[],
      persistentStatePlugin: Handsontable.plugins.PersistenState,
    ) {
      const movedColumns = columns.map((column, index) => this.hot?.toPhysicalColumn(index));
      const orderedList = Array.from(Array(movedColumns.length).keys());

      if (_isEqual(movedColumns, orderedList)) {
        persistentStatePlugin.resetValue('manualColumnMove');
      } else {
        persistentStatePlugin.saveValue('manualColumnMove', movedColumns);
      }
    },
    updatePersistentStateHiddenColumns(persistentStatePlugin: Handsontable.plugins.PersistenState) {
      persistentStatePlugin.saveValue('hiddenColumns', this.hot?.getPlugin('hiddenColumns').getHiddenColumns());
    },
    restorePersistentState() {
      if (!this.hot) return;

      const persistentStatePlugin = this.hot?.getPlugin('persistentState');
      if (!persistentStatePlugin?.isEnabled()) return;

      this.restorePersistentStateManualColumnMove(persistentStatePlugin);
      this.restorePersistentStateHiddenColumns(persistentStatePlugin);
      this.restorePersistentStateColumnSorting(persistentStatePlugin);
    },
    restorePersistentStateManualColumnMove(persistentStatePlugin: Handsontable.plugins.PersistenState) {
      if (!this.hot) return;

      const responseManualColumnMove: { value?: number[] } = {};
      persistentStatePlugin.loadValue('manualColumnMove', responseManualColumnMove);

      if (Array.isArray(responseManualColumnMove.value)) {
        // manualColumnMove will be restored by persistentStatePlugin
        // but for subtable we need to reinitialize the mergeCells
        Handsontable.hooks.run(this.hot, 'afterColumnMove');
      }
    },
    restorePersistentStateHiddenColumns(persistentStatePlugin: Handsontable.plugins.PersistenState) {
      if (!this.hot) return;

      const responseHiddenColumns: { value?: number[] } = {};
      persistentStatePlugin.loadValue('hiddenColumns', responseHiddenColumns);

      if (Array.isArray(responseHiddenColumns.value)) {
        const hiddenColumnsPlugin = this.hot.getPlugin('hiddenColumns');
        const allColumns = this.tableColumns;

        // If user has set a column to visible that is initially hidden, we want to recover the user's choice.
        // Therefore, we first need to show all columns and then hide the hidden columns.
        // If we would just hide the persisted hidden columns, we would ignore the users choice of columns that the user set to visible, because during initial load of column settings, those columns were wrongly initialized with visibility set to hidden.
        // (we need persisted visibility instead of default visibility of columns).
        // see https://git.farmdok.com/farmdok/app-webclient/-/issues/1396
        hiddenColumnsPlugin.showColumns(allColumns.map((column) => column.visualColumn));
        hiddenColumnsPlugin.hideColumns(responseHiddenColumns.value);
      }
    },
    restorePersistentStateColumnSorting(persistentStatePlugin: Handsontable.plugins.PersistenState) {
      if (!this.hot) return;

      const responseColumnSorting: {
        value?: Handsontable.columnSorting.Settings & { initialConfig: Handsontable.columnSorting.Config[] };
      } = {};
      persistentStatePlugin.loadValue('columnSorting', responseColumnSorting);
      //
      if (responseColumnSorting.value && responseColumnSorting.value.initialConfig.length > 0) {
        // columnSorting will be restored by persistentStatePlugin
        // but for subtable we need to reinitialize the mergeCells
        Handsontable.hooks.run(this.hot, 'afterColumnSort');
      }
    },
    hasPersistentStateColumnSorting(): boolean {
      if (!this.hot) return false;

      const persistentStatePlugin = this.hot?.getPlugin('persistentState');
      if (!persistentStatePlugin?.isEnabled()) return false;

      const responseColumnSorting: {
        value?: Handsontable.columnSorting.Settings & { initialConfig: Handsontable.columnSorting.Config[] };
      } = {};
      persistentStatePlugin.loadValue('columnSorting', responseColumnSorting);
      return (responseColumnSorting.value && responseColumnSorting.value.initialConfig.length > 0) ?? false;
    },
    validatePersistentState(columns: ColumnSettingsFlattenedSubtable[]) {
      if (columns.length === 0) return;

      const checkSumCurrent = columns.reduce((checkSum, column) => `${checkSum}${column.data}`, '');
      const checkSumStored = localStorage.getItem(`${this.tableId}_checkSum`);
      if (checkSumCurrent === checkSumStored) return;

      const persistentStatePlugin = this.hot?.getPlugin('persistentState');
      if (!persistentStatePlugin?.isEnabled()) return;
      persistentStatePlugin.resetValue('manualColumnMove');
      persistentStatePlugin.resetValue('hiddenColumns');
      persistentStatePlugin.resetValue('columnSorting');
      localStorage.removeItem(`${this.tableId}__persistentStateKeys`);
      localStorage.setItem(`${this.tableId}_checkSum`, checkSumCurrent);
    },
    afterChangeHookEmitOnChangeEvent(
      changes: Handsontable.CellChange[] | null,
      source?: Handsontable.ChangeSource | FarmdokChangeSource.populateFromTableDataChange,
    ) {
      if (changes === null || changes.length === 0) return;
      if (source === FarmdokChangeSource.populateFromTableDataChange) return;
      if (this.hot === null) return;

      const changesWithIds = addIdsToChanges(changes, this.hot);
      this.$emit('onChange', changesWithIds, source);
    },
    createTableSettings(
      tableData: TableDataAssessment[],
      columns: ColumnSettingsWithUserSettings[],
    ): Handsontable.GridSettings {
      const { hiddenRows } = this.tableSettingsThatDependOnTableData(tableData);
      const { colHeaders, columns: flattenedColumns, hiddenColumns } = this.tableSettingsThatDependOnColumns(columns);
      return {
        colHeaders,
        licenseKey: HOT_LICENSE_KEY,
        columns: flattenedColumns,
        autoColumnSize: false,
        hiddenRows,
        hiddenColumns,
        multiColumnSorting: {
          initialConfig: [
            {
              column: 2,
              sortOrder: 'desc',
            },
            {
              column: 3,
              sortOrder: 'asc',
            },
          ],
        },
        fixedColumnsLeft: this.fixedColumnsLeft,
        manualColumnMove: true,
        manualColumnResize: true,
        trimRows: true,
        stretchH: 'last',
        persistentState: true,
        selectionMode: 'single',
        beforeChange: (changes: Array<Handsontable.CellChange | null>, source?: Handsontable.ChangeSource) => {
          const hooks = [
            removeChangesToSameValues.beforeChange,
            nestedTableHooks.beforeChange.propagateChangesToMergedCellsFactory(this.hot),
            expandHooks.beforeChange.showHideRowsFactory(this.hot),
          ];

          hooks.forEach((hook) => {
            hook(changes, source);
          });
        },
        afterChange: (changes: Handsontable.CellChange[] | null, source?: Handsontable.ChangeSource) => {
          const hooks = [
            nestedTableHooks.afterChange.filterChangesToMergedCellsFactory(this.hot),
            this.afterChangeHookEmitOnChangeEvent,
          ];

          hooks.forEach((hook) => {
            hook(changes, source);
          });
        },
        afterColumnResize: (newSize: number, visualColumn: number, isDoubleClick: boolean) => {
          if (!this.hot) return;

          const hooks = [
            placeholderColumnHooks.afterColumnResize.setMinPlaceholderColumnWidthFactory(this.hot),
            manualColumnResizeHooks.afterColumnResize.disableColumnResizeFactory(this.hot),
          ];

          hooks.forEach((hook) => {
            hook(newSize, visualColumn, isDoubleClick);
          });

          this.hot.render();
        },
        beforeColumnMove: (
          movedColumns: number[],
          finalIndex: number,
          dropIndex: number | void,
          movePossible: boolean,
        ): void | boolean => {
          if (!this.hot) return true; // ignore hooks if hot is not yet initialized

          const hooks = [
            manualColumnMoveHooks.beforeColumnMove.preventMoveToSamePosition,
            manualColumnMoveHooks.beforeColumnMove.preventMoveOfFixedColumnsFactory(this.hot, this.fixedColumnsLeft),
            manualColumnMoveHooks.beforeColumnMove.preventMoveIntoFixedColumnsFactory(this.fixedColumnsLeft),
            nestedTableHooks.beforeColumnMove.moveAllColumnsOfSubtableFactory(this.hot),
            nestedTableHooks.beforeColumnMove.preventMoveBetweenSubtableColumnsFactory(this.hot),
            placeholderColumnHooks.beforeColumnMove.preventMoveOfPlaceholderColumnFactory(this.hot),
          ];

          const returnValue = hooks.every((hook) => hook(movedColumns, finalIndex, dropIndex, movePossible));

          return returnValue;
        },
        afterGetColHeader: (col: number, TH: HTMLTableCellElement) => {
          if (!this.hot) return;

          const hooks = [colHeadersSelectHooks.afterGetColHeader.addCheckboxClassToColumnHeadersFactory(this.hot)];

          hooks.forEach((hook) => {
            hook(col, TH);
          });
        },
        afterOnCellMouseDown: (event: MouseEvent, coords: CellCoords, TD: HTMLTableCellElement) => {
          const hooks = [
            colHeadersSelectHooks.afterOnCellMouseDown.selectClickHandlerFactory(this.selectAllVisible),
            optionalRendererHooks.afterOnCellMouseDown.triggerOnClickFactory(this.hot),
            collapsedSubtableHooks.afterOnCellMouseDown.triggerOnClickFactory(this.hot),
          ];

          hooks.forEach((hook) => {
            hook(event, coords, TD);
          });
        },
        beforeOnCellMouseDown: (event: MouseEvent, coords: CellCoords) => {
          const hooks = [colHeadersSelectHooks.beforeOnCellMouseDown.multiSelectClickHandlerFactory(this.hot)];

          hooks.forEach((hook) => {
            hook(event, coords);
          });
        },
        beforeKeyDown: (event: KeyboardEvent) => {
          const hooks = [
            expandHooks.beforeKeyDown.handleKeyClickFactory(this.hot),
            optionalCheckboxHooks.beforeKeyDown.handleKeyClickFactory(this.hot),
            optionalRendererHooks.beforeKeyDown.handleKeyClickFactory(this.hot),
            collapsedSubtableHooks.beforeKeyDown.handleKeyClickFactory(this.hot),
            keyboardNavigationHooks.beforeKeyDown.handleKeyClickFactory(this.hot),
          ];

          hooks.forEach((hook) => {
            hook(event);
          });
        },
        afterRenderer: (
          TD: HTMLTableCellElement,
          row: number,
          col: number,
          prop: string | number,
          value: any,
          cellProperties: Handsontable.CellProperties,
        ) => {
          if (!this.hot) return;

          const hooks = [
            colspanHooks.afterRenderer.applyColspanFactory(this.hot),
            rowBackgroundStoreStatusHooks.afterRenderer.addSyncStatusBackgroundFactory(this.hot),
          ];
          hooks.forEach((hook) => {
            hook(TD, row, col, prop, value, cellProperties);
          });
        },
      };
    },
    tableSettingsThatDependOnTableData(tableData: TableDataAssessment[]): {
      hiddenRows: Handsontable.GridSettings['hiddenRows'];
    } {
      return {
        hiddenRows: nestedTableSettings.hiddenRows(tableData),
      };
    },
    tableSettingsThatDependOnColumns(columns: ColumnSettingsWithUserSettings[]): {
      colHeaders: Handsontable.GridSettings['colHeaders'];
      columns: ColumnSettingsFlattenedSubtable[];
      hiddenColumns: Handsontable.GridSettings['hiddenColumns'];
    } {
      const flattenedColumns = nestedTableSettings.columns(columns);
      const colHeadersHooks = [
        colHeadersTitleHook(flattenedColumns),
        colHeadersSelectHook(flattenedColumns, this.allRowsSelected),
      ];
      return {
        colHeaders: colHeadersSettings.colHeaders.colHeadersHooksFactory(colHeadersHooks),
        columns: flattenedColumns,
        hiddenColumns: hiddenColumnSettings.hiddenColumns(flattenedColumns),
      };
    },
    filterWatchHandler() {
      if (!this.hot || !this.tableColumns.length) return;
      if (this.filterActive) {
        filterTable(
          this.hot,
          this.filterString,
          this.filtersByColumnKey,
          this.filtersByDate,
          this.tableData,
          this.tableColumns,
        );
      } else if (!this.filtersByDate) {
        resetFilter(this.hot);
      }
      Handsontable.hooks.run(this.hot, 'afterFilter', []);
      this.hot.render();
    },
    onFiltersByColumnKey(columnKey: string, filter: FilterByColumnKey | null) {
      this.setFilterByColumnKey(columnKey, filter);
    },
    onFiltersByDate(filter: FilterByDate | null) {
      if (filter) {
        this.filtersByDate = filter;
      } else {
        this.filtersByDate = null;
      }
    },
    setFilterByColumnKey(columnKey: string, filter: FilterByColumnKey | null) {
      if (!filter) {
        Vue.delete(this.filtersByColumnKey, columnKey);
      } else {
        Vue.set(this.filtersByColumnKey, columnKey, filter);
      }
    },
    clearAllFilters() {
      this.$emit('update:filterString', '');
      this.filtersByColumnKey = {};
      this.filtersByDate = null;

      // @ts-ignore
      this.$refs.contextMenuInjector.$refs.contextMenu.resetFilterDates();
    },
    applyInitialSortConfig() {
      if (!this.hot) throw new Error("Can't apply initial sort config, hot is not initialized");

      const sortConfig = createInitialSortConfig(this.columns, this.hot);
      this.applySortConfig(sortConfig);
    },
    getCurrentSortConfig(): Handsontable.columnSorting.Config[] | undefined {
      if (!this.hot) throw new Error("Can't get current sort config, hot is not initialized");

      return this.getEnabledSortPlugin()?.getSortConfig();
    },
    applySortConfig(sortConfig: Handsontable.columnSorting.Config | undefined) {
      if (!sortConfig) return;
      if (!this.hot) throw new Error("Can't apply sort config, hot is not initialized");

      this.getEnabledSortPlugin()?.sort(sortConfig);
    },
    allRowsSelected(): boolean {
      if (!this.hot) return false;
      const col = this.hot.propToCol('select');
      const selectValues = this.hot.getDataAtCol(col);

      return selectValues.every((value) => value === true);
    },

    getEnabledSortPlugin(): Handsontable.plugins.ColumnSorting | Handsontable.plugins.MultiColumnSorting | null {
      if (!this.hot) return null;

      const columnSortPlugin = this.hot.getPlugin('columnSorting');
      if (columnSortPlugin?.isEnabled()) {
        return columnSortPlugin;
      }

      const multiColumnSortPlugin = this.hot.getPlugin('multiColumnSorting');
      if (multiColumnSortPlugin?.isEnabled()) {
        return multiColumnSortPlugin;
      }

      return null;
    },

    //
    // "public" methods that can also be called from outside the component
    //
    selectAllVisible(select: boolean) {
      if (!this.hot) return;

      const rows = this.hot.countRows();
      this.hot.batch(() => {
        for (let i = 0; i < rows; i += 1) {
          this.hot?.setDataAtRowProp(i, 'select', select);
        }
      });
    },
    invertSelection() {
      if (!this.hot) return;

      const rows = this.hot.countRows();
      const col = this.hot.propToCol('select');
      const selectValues = this.hot.getDataAtCol(col);
      this.hot.batch(() => {
        for (let i = 0; i < rows; i += 1) {
          this.hot?.setDataAtRowProp(i, 'select', !selectValues[i]);
        }
      });
    },
    selectedRows(): string[] {
      if (!this.hot) return [];
      const col = this.hot.propToCol('select');
      const selectValues = this.hot.getDataAtCol(col);
      return selectValues
        .map((value: boolean, index: number) => (value ? this.hot?.getDataAtRowProp(index, 'id') : null))
        .filter(notNullOrUndefined);
    },
  },
});
