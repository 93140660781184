
import { defineComponent } from 'vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import ResultTable from '@/precision-farming/application-maps/spraying/components/ResultTable.vue';
import type {
  ApplicationMapsSprayingState,
  PlantProtection,
  SummaryData,
  WorkingMean,
} from '@/precision-farming/application-maps/spraying/store/types';
import type { Product } from '@/shared/api/rest/models';
import MultiRootWrapper from '@/shared/components/MultiRootWrapper.vue';
import ResetButton from '@/shared/components/buttons/ResetButton.vue';

export default defineComponent({
  name: 'ResultTableContainer',
  components: { MultiRootWrapper, ResetButton, ResultTable },
  computed: {
    ...mapState('precisionFarming/applicationMaps/spraying', {
      reduction(state: ApplicationMapsSprayingState) {
        return state.calculation.reduction;
      },
      sprayMix(state: ApplicationMapsSprayingState) {
        return state.calculation.sprayMix;
      },
      protections(state: ApplicationMapsSprayingState) {
        return state.calculation.products;
      },
    }),
    ...mapGetters('precisionFarming/applicationMaps/spraying', [
      'zones',
      'areas',
      'reducedSprayMixPerZone',
      'reducedVegetationPerZone',
      'sprayMixMid',
      'isOverwritten',
    ]),
    ...mapGetters('products', ['findProductById']),
    ...mapGetters('pesticideIndications', {
      findIndicationByProductAndPestId: 'findByProductAndPestId',
    }),
    ...mapActions('precisionFarming/applicationMaps/spraying', ['resetOverwrite']),
    summaryData: {
      get(): SummaryData {
        return {
          reducedSprayMixPerZone: this.reducedSprayMixPerZone as number[],
          reducedVegetationPerZone: this.reducedVegetationPerZone as number[],
        };
      },
      set(summaryData: SummaryData) {
        // Unfortunately. I cannot map this aciton like I did above with resetOverwrite, because compiler complains as this is a different scope than the component's --> downside of using the Options API
        this.$store.dispatch('precisionFarming/applicationMaps/spraying/updateOverwrite', { summaryData });
      },
    },
  },
  methods: {
    ...mapMutations('precisionFarming/applicationMaps/spraying', ['setWorkingMeans']),
    updateWorkingMeans(means: number[]) {
      const workingMeans = this.protections.map((protection, index) => this.toWorkingMean(protection, means[index]));
      this.setWorkingMeans(workingMeans);
    },
    toWorkingMean(protection: PlantProtection, computedMean: number): WorkingMean {
      const productId = protection.product.id ?? '';
      const [pestId, pesticideIndicationId] = protection.pest.id?.split('_') || ['', ''];
      const product: Product | undefined = this.findProductById(productId);

      return {
        workingMeanId: productId,
        pestId,
        pesticideIndicationId,
        unitId: product?.unitId ?? '',
        amount: computedMean,
      };
    },
  },
});
