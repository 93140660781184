
import { faCloud as faCloudDuotone } from '@fortawesome/pro-duotone-svg-icons';
import moment, { type Moment } from 'moment';
import Vue, { type PropType, defineComponent } from 'vue';
import { Day } from 'vuejs-datepicker';
import { type GetterTree, mapState } from 'vuex';

import ControlNumZones from '@/precision-farming/application-maps/controls/ControlNumZones.vue';
import ControlSatelliteIndex from '@/precision-farming/application-maps/controls/ControlSatelliteIndex.vue';
import type {
  BaseWorkflowStoreModuleState,
  IndexType,
} from '@/precision-farming/application-maps/store/baseWorkflowStore/types';
import type { ApplicationMapsState } from '@/precision-farming/application-maps/store/types';
import FormFieldDatepicker from '@/shared/components/form/FormFieldDatepicker.vue';
import FormFieldSetBordered from '@/shared/components/form/FormFieldSetBordered.vue';
import type { DropdownItem, DropdownItemsPerCategory } from '@/shared/components/form/formFieldDropdownTypes';
import iconAsSvgString from '@/shared/modules/iconAsSvgString';
import type { RootState } from '@/store/types';

const t = Vue.i18n.translate;

const cloudIcon = iconAsSvgString(faCloudDuotone);

/**
 * A form is container-like. I.e. it can contain references to the store, and can execute actions and sync state
 */
export default defineComponent({
  name: 'FormZones',
  components: {
    FormFieldDatepicker,
    FormFieldSetBordered,
    ControlNumZones,
    ControlSatelliteIndex,
  },
  props: {
    workflowKey: {
      type: String as PropType<keyof ApplicationMapsState>,
      required: true,
    },
  },
  data(): {
    activeDate: Date;
    activeIndexType?: DropdownItem<{ id: string; name: string }>;
    activeQuantisationCode: DropdownItem<{ id: string; name: string }>;
  } {
    return {
      activeDate: new Date(),
      activeIndexType: undefined,
      activeQuantisationCode: { id: 'medium', name: t('Mittel')! },
    };
  },
  computed: {
    ...mapState('precisionFarming/applicationMaps', {
      selectedFieldIds(state: ApplicationMapsState): string[] {
        return state[this.workflowKey as keyof ApplicationMapsState].selectedFields;
      },
      indexTypeSelected(state: ApplicationMapsState, getters: GetterTree<ApplicationMapsState, RootState>) {
        return getters[`${this.workflowKey}/selectedIndexType`];
      },
      heatmaps(state: ApplicationMapsState) {
        const heatmaps = state[this.workflowKey as keyof ApplicationMapsState].heatmaps.current;
        return Object.entries(heatmaps)
          .filter(([key]) => key.includes(this.selectedQuantisationCode.id))
          .filter(([key]) => key.includes(this.selectedIndex.id))
          .map(([, value]) => value);
      },
      loadingHeatmaps(state: ApplicationMapsState) {
        return state[this.workflowKey as keyof ApplicationMapsState].heatmaps.fetching;
      },
      availability(state: ApplicationMapsState, getters: GetterTree<ApplicationMapsState, RootState>) {
        return getters[`${this.workflowKey as keyof ApplicationMapsState}/availableTimestamps`];
      },
      heatmapTimestampSelectedIndex(state: ApplicationMapsState, getters: GetterTree<ApplicationMapsState, RootState>) {
        return getters[`${this.workflowKey}/heatmapTimestampSelectedIndex`];
      },
    }),
    availableTimestamps(): { timeArray: number[]; mapping: Record<number, string> } {
      // important: indexing the getters makes the type unidentifiable to TS, and we cannot type the computed property
      return this.availability as unknown as { timeArray: number[]; mapping: Record<number, string> };
    },
    availableDates(): Moment[] {
      const { timeArray } = this.availableTimestamps;
      return timeArray.sort((a, b) => a - b).map((stamp) => moment.unix(stamp));
    },
    indexTypes(): DropdownItemsPerCategory[] {
      return this.$store.getters[`precisionFarming/applicationMaps/${this.workflowKey}/indexTypes`];
    },
    selectedDate: {
      set(date: Date) {
        this.activeDate = date;
        const selectedDate = moment(date).startOf('day');
        const { timeArray } = this.availableTimestamps as unknown as {
          timeArray: number[];
          mapping: Record<number, string>;
        };
        const matchingTimestamp =
          timeArray.find((unix) => moment.unix(unix).isSame(selectedDate, 'day')) ?? selectedDate.unix();
        this.setHeatmapTimestamp(matchingTimestamp);
      },
      get(): Date {
        const state: BaseWorkflowStoreModuleState =
          this.$store.state[`precisionFarming/applicationMaps/${this.workflowKey}`];
        const heatmapTimestamp = state?.selectedHeatmapTimestamp;

        return heatmapTimestamp ? moment.unix(heatmapTimestamp).toDate() : this.activeDate;
      },
    },
    disableIf() {
      const { selectedIndex, isForecastable, availableDates } = this;
      return (date: Date) => {
        if (isForecastable(selectedIndex)) {
          return false;
        }

        const selectedDate = moment(date);
        return !availableDates.some((d) => d.isSame(selectedDate, 'day'));
      };
    },
    selectedIndex: {
      set(newIndexType: DropdownItem<{ id: string; name: string }>) {
        this.activeIndexType = newIndexType;
        this.setSelectedIndexType(newIndexType.id as IndexType);
      },
      get(): DropdownItem<{ id: string; name: string }> {
        return this.activeIndexType ?? this.initialIndexType();
      },
    },
    selectedQuantisationCode: {
      set(quantisationCode: DropdownItem<{ id: string; name: string }>) {
        this.activeQuantisationCode = quantisationCode;
        this.$store.commit(
          `precisionFarming/applicationMaps/${this.workflowKey}/setSelectedQuantisationCode`,
          quantisationCode.id,
        );
        this.$store.dispatch(`precisionFarming/applicationMaps/${this.workflowKey}/loadHeatmaps`);
      },
      get(): DropdownItem<{ id: string; name: string }> {
        return this.activeQuantisationCode;
      },
    },
  },
  methods: {
    mostRecentDate(): Date {
      const mostRecent = this.availableDates.find((_, index, arr) => index === arr.length - 1)?.toDate();
      return mostRecent ?? new Date();
    },
    isForecastable(indexType: DropdownItem<{ id: string; name: string }>): boolean {
      return indexType.id?.includes('DNN_') ?? false;
    },
    dayCellContent(day: Day): string {
      if (day.isHighlighted) {
        return `${day.date}${cloudIcon}`;
      }

      return `${day.date}`;
    },
    initialIndexType(): DropdownItem<{ id: string; name: string }> {
      const indexTypeId = this.$store.state.precisionFarming.applicationMaps[this.workflowKey].selectedIndexType;
      const emptyDropdownItem = { id: '', name: '' };
      const foundDropdownItem = this.indexTypes
        .flatMap((indexType) => indexType.items)
        .find((item) => item.id === indexTypeId);
      return foundDropdownItem ?? emptyDropdownItem;
    },
    setSelectedIndexType(indexType: IndexType) {
      this.$store.commit(`precisionFarming/applicationMaps/${this.workflowKey}/setSelectedIndexType`, indexType);
    },
    setHeatmapTimestamp(timestamp: number): void {
      this.$store.commit(`precisionFarming/applicationMaps/${this.workflowKey}/setHeatmapTimestamp`, timestamp);
    },
    async loadHeatmaps(): Promise<void> {
      return this.$store.dispatch(`precisionFarming/applicationMaps/${this.workflowKey}/loadHeatmaps`);
    },
  },
  watch: {
    selectedDate() {
      this.loadHeatmaps();
    },
    async selectedIndex(
      current: DropdownItem<{ id: string; name: string }>,
      previous: DropdownItem<{ id: string; name: string }>,
    ) {
      const promises = this.selectedFieldIds.map((fieldKey: string) =>
        this.$store.dispatch(`precisionFarming/applicationMaps/${this.workflowKey}/setMultipolyTimestamp`, fieldKey),
      );
      await Promise.all(promises);

      if (this.isForecastable(previous) && !this.isForecastable(current)) {
        this.selectedDate = this.mostRecentDate();
      }

      this.loadHeatmaps();
    },
  },
});
