var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import moment from 'moment';
const actions = {
    reset({ commit, state }) {
        Object.values(state.polygons).forEach((polygon) => {
            polygon.setMap(null);
        });
        commit('reset');
    },
    setPolygonState({ state, commit, dispatch }, polygon) {
        Object.keys(polygon.values).forEach((key) => __awaiter(this, void 0, void 0, function* () {
            const polygonKey = polygon.key;
            const copiedPolygon = Object.assign({}, polygon);
            copiedPolygon.property = key;
            copiedPolygon.value = polygon.values[key];
            commit('setPolygonProperty', copiedPolygon);
            if (key === 'state') {
                commit('setOverlayLoading');
                if (polygon.values[key] === 'active') {
                    yield dispatch('setPolygonVegetationData', polygonKey);
                    yield dispatch('setMultipolyTimestamp', polygonKey);
                    if (state.selectedHeatmapDbId) {
                        dispatch('setCurrentHeatmaps', state.selectedHeatmapDbId);
                    }
                    else {
                        const latestDbIdIndex = state.multiPolyTimestamps.loaded[polygonKey].availableData.length - 1;
                        dispatch('setCurrentHeatmaps', state.multiPolyTimestamps.loaded[polygonKey].availableData[latestDbIdIndex].dbId);
                    }
                }
                else if (polygon.values[key] === 'inactive') {
                    commit('unsetCurrentMultipolyTimestamp', polygonKey);
                    commit('unsetCurrentVegetationData', polygonKey);
                    commit('unsetCurrentHeatmap', polygonKey);
                }
                commit('unsetOverlayLoading');
            }
        }));
    },
    /**
     * Sets the polygon state to the desired state, but is specific to DNN_ index types, because those don't require preloaded heatmaps.
     */
    setDnnPolygonState({ commit }, polygon) {
        Object.keys(polygon.values).forEach((key) => __awaiter(this, void 0, void 0, function* () {
            const copiedPolygon = Object.assign({}, polygon);
            copiedPolygon.property = key;
            copiedPolygon.value = polygon.values[key];
            commit('setPolygonProperty', copiedPolygon);
        }));
    },
    setCurrentHeatmaps({ state, dispatch, commit }, heatMapDbId) {
        return __awaiter(this, void 0, void 0, function* () {
            commit('setSelectedHeatmapDbId', heatMapDbId);
            const activePolygons = Object.keys(state.polygons).filter((key) => state.polygons[key].state === 'active');
            const newCurrentHeatmaps = {};
            const heatmapsToLoad = [];
            activePolygons.forEach((polygonKey) => {
                const heatmap = state.heatmaps.loaded[`${polygonKey}_${heatMapDbId}_${state.selectedIndexType}`];
                if (heatmap) {
                    newCurrentHeatmaps[polygonKey] = heatmap;
                }
                else {
                    heatmapsToLoad.push({
                        dbId: state.selectedHeatmapDbId,
                        polygon: state.polygons[polygonKey].pathsForAxios,
                        clientId: `${polygonKey}_${heatMapDbId}_${state.selectedIndexType}`,
                        fieldKey: polygonKey,
                    });
                }
            });
            if (heatmapsToLoad.length) {
                const loadedHeatmaps = yield dispatch('loadHeatmaps', heatmapsToLoad);
                Object.keys(loadedHeatmaps.products).forEach((heatmapKey) => {
                    const newHeatmapKey = heatmapKey.split('_')[0];
                    newCurrentHeatmaps[newHeatmapKey] = loadedHeatmaps.products[heatmapKey];
                });
                commit('addHeatmaps', loadedHeatmaps.products);
            }
            commit('setCurrentHeatmaps', newCurrentHeatmaps);
        });
    },
    /**
     * Copy of {@link setCurrentHeatmaps}, but is specific to DNN_ index types. Main difference is that, for DNN_ index types we don't need to query timestamps beforehand.
     * Make sure to set the timestamp and index type before loading DNN heatmaps.
     */
    loadDnnHeatmaps({ state, dispatch, commit }) {
        return __awaiter(this, void 0, void 0, function* () {
            const activePolygons = Object.keys(state.polygons).filter((key) => state.polygons[key].state === 'active');
            const newCurrentHeatmaps = {};
            const heatmapsToLoad = [];
            activePolygons.forEach((polygonKey) => {
                const heatmap = state.heatmaps.loaded[`${polygonKey}_${state.selectedTimestamp}_${state.selectedIndexType}`];
                if (heatmap) {
                    newCurrentHeatmaps[polygonKey] = heatmap;
                }
                else {
                    heatmapsToLoad.push({
                        polygon: state.polygons[polygonKey].pathsForAxios,
                        clientId: `${polygonKey}_${state.selectedTimestamp}_${state.selectedIndexType}`,
                        fieldKey: polygonKey,
                        timestamp: state.selectedTimestamp,
                    });
                }
            });
            if (heatmapsToLoad.length) {
                const loadedHeatmaps = yield dispatch('loadHeatmaps', heatmapsToLoad);
                Object.keys(loadedHeatmaps.products).forEach((heatmapKey) => {
                    const newHeatmapKey = heatmapKey.split('_')[0];
                    newCurrentHeatmaps[newHeatmapKey] = loadedHeatmaps.products[heatmapKey];
                });
                commit('addHeatmaps', loadedHeatmaps.products);
            }
            commit('setCurrentHeatmaps', newCurrentHeatmaps);
        });
    },
    loadHeatmaps({ state, commit }, products) {
        return __awaiter(this, void 0, void 0, function* () {
            commit('setOverlayLoading');
            const { data } = yield axios.post('/admin/sen4/multiPoly', {
                products,
                indexType: state.selectedIndexType,
            });
            commit('unsetOverlayLoading');
            return data;
        });
    },
    setPolygonVegetationData({ state, commit }, polygonKey) {
        return __awaiter(this, void 0, void 0, function* () {
            const polygon = state.polygons[polygonKey];
            // @ts-ignore
            const vegetationDataFromPolygon = state.vegetationData[`${polygonKey}_${state.selectedIndexType}`];
            let vegetationData = {};
            if (vegetationDataFromPolygon) {
                vegetationData = {
                    key: polygonKey,
                    data: vegetationDataFromPolygon,
                };
            }
            else {
                const { data } = yield axios.post('/admin/sen4/vegetationIndexGraph', {
                    polygon: polygon.pathsForAxios,
                    // @ts-ignore
                    timeStart: Math.floor((new Date() - 1000 * 60 * 60 * 24 * 365 * 2) / 1000),
                    // @ts-ignore
                    timeEnd: Math.floor(new Date() / 1000),
                    indexType: state.selectedIndexType,
                });
                vegetationData = {
                    key: polygonKey,
                    data,
                };
                commit('addVegetationData', vegetationData);
            }
            if (state.polygons[polygonKey].state === 'active') {
                commit('setCurrentVegetationData', vegetationData);
            }
            return 1;
        });
    },
    setMultipolyTimestamp({ state, commit }, polygonKey) {
        return __awaiter(this, void 0, void 0, function* () {
            const polygon = state.polygons[polygonKey];
            // @ts-ignore
            const multiPolyFromPolygon = state.multiPolyTimestamps[polygonKey];
            let multiPolyTimestamp = {};
            if (multiPolyFromPolygon) {
                multiPolyTimestamp = {
                    key: polygonKey,
                    data: multiPolyFromPolygon,
                };
            }
            else {
                const { data } = yield axios.post('/admin/sen4/multiPolyTimestamps', {
                    polygon: polygon.pathsForAxios,
                    // @ts-ignore
                    timeStart: Math.floor((new Date() - 1000 * 60 * 60 * 24 * 365 * 2) / 1000),
                    // @ts-ignore
                    timeEnd: Math.floor(new Date() / 1000),
                    coverageRatio: 2,
                });
                const availableData = Object.values(data.availableData.reduce((timestampsPerDay, value) => {
                    const day = moment.unix(value.timestamp).format('DDMMYYYY');
                    if (timestampsPerDay[day] != null) {
                        return timestampsPerDay;
                    }
                    return Object.assign(Object.assign({}, timestampsPerDay), { [day]: value });
                }, {}));
                multiPolyTimestamp = {
                    key: polygonKey,
                    data: { availableData },
                };
                commit('addMultipolyTimestamp', multiPolyTimestamp);
            }
            if (state.polygons[polygonKey].state === 'active') {
                commit('setCurrentMultipolyTimestamp', multiPolyTimestamp);
            }
            return 1;
        });
    },
    setSelectedIndexType({ state, commit, dispatch }, indexType) {
        const activePolygons = Object.keys(state.polygons).filter((key) => state.polygons[key].state === 'active');
        commit('setSelectedIndexType', indexType);
        dispatch('setCurrentHeatmaps', state.selectedHeatmapDbId);
        activePolygons.forEach((polygonKey) => __awaiter(this, void 0, void 0, function* () {
            commit('setOverlayLoading');
            yield dispatch('setPolygonVegetationData', polygonKey);
            commit('unsetOverlayLoading');
        }));
    },
    /**
     * Assigns the given value in the store's state. It's specific to DNN_ index types, because those dont't  Main difference is that, for DNN_ index types we don't need to query timestamps beforehand.
     */
    setSelectedDnnIndexType({ commit }, indexType) {
        commit('setSelectedIndexType', indexType);
    },
    setSelectedTimestamp({ commit }, timestamp) {
        commit('setSelectedTimestamp', timestamp);
    },
    closeOverlay({ state, commit, dispatch }) {
        const activeInternalPolygons = Object.keys(state.polygons).filter((key) => state.polygons[key].state === 'active' && !state.polygons[key].external);
        const allExternalPolygons = Object.keys(state.polygons).filter((key) => state.polygons[key].external);
        commit('unsetCurrentHeatmaps');
        commit('unsetCurrentMultipolyTimestamps');
        commit('unsetAllCurrentVegetationData');
        commit('setSelectedFields', []);
        activeInternalPolygons.forEach((key) => {
            dispatch('setPolygonState', {
                key,
                values: {
                    state: 'inactive',
                },
            });
        });
        allExternalPolygons.forEach((key) => {
            dispatch('setPolygonState', {
                key,
                values: {
                    state: 'hidden',
                },
            });
        });
    },
};
export default actions;
