
import { type PropType, defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';

import ControlPestSelection from '@/precision-farming/application-maps/controls/ControlPestSelection.vue';
import ControlProductAmount from '@/precision-farming/application-maps/controls/ControlProductAmount.vue';
import ControlProductSelection from '@/precision-farming/application-maps/controls/ControlProductSelection.vue';
import type { PlantProtection } from '@/precision-farming/application-maps/spraying/store/types';
import type { ApplicationMapsState } from '@/precision-farming/application-maps/store/types';
import type { ActivityType, Unit } from '@/shared/api/rest/models';
import ButtonLink from '@/shared/components/buttons/ButtonLink.vue';
import type { DropdownItem } from '@/shared/components/form/formFieldDropdownTypes';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

/**
 * A form control that enables two-way binding of a plant protection product and its amount. The object in this control is reactively bound and is independent of given context/domain. Some forms in precision farming utilize this control, but you can also use it separately, outside those forms.
 */
export default defineComponent({
  name: 'ControlPlantProtection',
  components: {
    ButtonLink,
    ControlProductAmount,
    ControlPestSelection,
    ControlProductSelection,
  },
  props: {
    plantProtection: {
      type: Object as PropType<PlantProtection>,
      required: false,
      default: () => ({
        product: { id: '', name: '' },
        pest: { id: '', name: '' },
        amount: 0,
        unit: '',
      }),
    },
    workflowKey: {
      type: String as PropType<keyof ApplicationMapsState>,
      required: true,
    },
    activityType: {
      type: Object as PropType<ActivityType>,
      required: false,
      default: () => undefined,
    },
  },
  emits: ['update:plantProtection'],
  model: {
    prop: 'plantProtection',
    event: 'update:plantProtection',
  },
  computed: {
    product: {
      get(): DropdownItem<{ id: string; name: string }> {
        return this.plantProtection.product;
      },
      set(product: DropdownItem<{ id: string; name: string }>) {
        const newPlantProtection = {
          ...this.plantProtection,
          product,
          pest: { id: '', name: '' },
          unit: this.unit(product.id ?? '')?.name || '',
        };
        this.$emit('update:plantProtection', newPlantProtection);
      },
    },
    pest: {
      get(): DropdownItem<{ id: string; name: string }> {
        return this.plantProtection.pest;
      },
      set(pest: DropdownItem<{ id: string; name: string }>) {
        const newPlantProtection = {
          ...this.plantProtection,
          pest,
        };
        this.$emit('update:plantProtection', newPlantProtection);
      },
    },
    amount: {
      get(): number {
        return this.plantProtection.amount;
      },
      set(amount: number) {
        const newPlantProtection = {
          ...this.plantProtection,
          amount,
        };
        this.$emit('update:plantProtection', newPlantProtection);
      },
    },
    ...mapGetters('products', ['findProductById']),
    ...mapGetters('productCategories', ['isHerbicide']),
    ...mapGetters('pesticideIndications', ['findByProductAndPestId']),
    featurePestsEnabled(): boolean {
      return this.$store.getters.currentCompaniesHaveFeatureEnabled(availableFeatures.FEATURE_PESTS);
    },
    featurePestsVisible(): boolean {
      return this.$store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_PESTS);
    },
    enablePest(): boolean {
      const product = this.findProductById(this.product.id);
      return this.featurePestsEnabled && this.featurePestsVisible && this.isHerbicide(product?.categoryId);
    },
    ...mapState('auth', ['currentCompanies']),
    ...mapState('units', {
      units: 'data',
    }),
    currentRegionCode(): string {
      if (this.currentCompanies.length) {
        return this.currentCompanies[0].region.code;
      }

      return '';
    },
    catalogLink(): string {
      const productId = this.product.id;
      const [pestId] = this.pest.id?.split('_') || [''];
      const indication = this.findByProductAndPestId(productId, pestId);
      if (!indication?.externalId) {
        return '';
      }

      const [refNr, indNr] = indication.externalId.split('/');

      if (this.currentRegionCode === 'de') {
        return `https://apps2.bvl.bund.de/psm/jsp/BlattAnwendg.jsp?awg_id=${indication.externalId}&kennr=${refNr}`;
      }

      return `https://psmregister.baes.gv.at/psmregister/faces/indication.jspx?refNr=${refNr}&indNr=${indNr}&locale=de`;
    },
  },
  methods: {
    unit(productId: string): Unit | undefined {
      const product = this.findProductById(productId);
      return this.units[product.unitId];
    },
  },
});
