import { ProductCategory, ProductCategoryType } from 'farmdok-rest-api';
import { GetterTree } from 'vuex';

import { subscribableStore } from '@/productCategories/store/common';
import { ProductCategoriesState } from '@/productCategories/store/types';
import { SubscribableGetters } from '@/shared/mixins/store/subscribableData/getters';
import { RootState } from '@/store/types';

export type Getters = SubscribableGetters<ProductCategory> & {
  isHerbicide(productCategoryId: string): boolean;
};

const moduleGetters: GetterTree<ProductCategoriesState, RootState> = {
  ...subscribableStore.getters,
  /**
   * Determines whether a product is of category herbicide or not. Attention: this getter tests by category type not function id.
   * This is type of categorization is different in FarmDok. This is different to {@link file://products/store/getters.ts#isHerbicide}
   * @param productCategoryId the product category against which we should test
   */
  isHerbicide:
    (state: ProductCategoriesState) =>
    (productCategoryId: string): boolean => {
      const productCategory = state.data[productCategoryId];
      return productCategory?.type === ProductCategoryType.Herb;
    },
};

export default moduleGetters;
