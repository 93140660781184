
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';

import DemandTable from '@/precision-farming/application-maps/spraying/components/DemandTable.vue';
import type { ApplicationMapsSprayingState } from '@/precision-farming/application-maps/spraying/store/types';

export default defineComponent({
  name: 'DemandTableContainer',
  components: { DemandTable },
  computed: {
    ...mapState('precisionFarming/applicationMaps/spraying', {
      protections(state: ApplicationMapsSprayingState) {
        return state.calculation.products;
      },
    }),
    ...mapGetters('precisionFarming/applicationMaps/spraying', [
      'zones',
      'reducedVegetationPerZone',
      'reducedSprayMixPerZone',
    ]),
  },
});
