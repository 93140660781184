import groupby from 'lodash.groupby';
import Vue from 'vue';
import { GetterTree } from 'vuex';

import type { DropdownItemsPerCategory } from '@/shared/components/form/formFieldDropdownTypes';
import { RootState } from '@/store/types';

import { BaseWorkflowStoreModuleState } from './types';
import { ColorCode, Feature, Heatmap } from './types/Heatmap';
import { Zone } from './types/Zone';

export type Getters = {
  paginationNextEnabled: boolean;
  selectedTaskDate: Date | null;
  fieldsWithHeatmap: string[];
  currentHeatmaps: Record<string, Heatmap>;
  heatmapsOfSelectedFields: Heatmap[];
  zonesByHeatmaps: Zone[];
};

const moduleGetters: GetterTree<BaseWorkflowStoreModuleState, RootState> = {
  paginationNextEnabled: () => false, // override in derived store module
  selectedTaskDate: (state: BaseWorkflowStoreModuleState) =>
    state.selectedTaskDate ? new Date(state.selectedTaskDate) : null,
  // fields
  fieldsWithHeatmap: (state: BaseWorkflowStoreModuleState) =>
    Object.keys(state.heatmaps.current).map((productId) => productId.split('_')[0]),
  currentHeatmaps: (state: BaseWorkflowStoreModuleState) => state.heatmaps.current,
  heatmapsOfSelectedFields: (state: BaseWorkflowStoreModuleState, getters: Getters): Heatmap[] => {
    const isInSelectedFields = ([key]: [string, Heatmap]): boolean =>
      state.selectedFields.find((fieldName: string) => key.startsWith(fieldName)) !== undefined;
    return Object.entries(getters.currentHeatmaps)
      .filter(isInSelectedFields)
      .map(([, value]) => value);
  },
  indexTypes: (): DropdownItemsPerCategory[] => {
    const items = [
      { id: 'DNN_NDVI', name: Vue.i18n.translate('NDVI - Biomasse')! },
      { id: 'REIP', name: Vue.i18n.translate('REIP - Stickstoffgehalt')! },
      { id: 'DNN_CIRE', name: Vue.i18n.translate('CIre - Chlorophyll')! },
      { id: 'DNN_NDWI', name: Vue.i18n.translate('NDWI - Wassergehalt')! },
      { id: 'DNN_LAI', name: Vue.i18n.translate('LAI - Blattflächenindex')! },
      { id: 'MBI', name: Vue.i18n.translate('MBI - Mehrjährige Biomasse')! },
    ];
    return [{ items }];
  },
  zonesByHeatmaps(state: BaseWorkflowStoreModuleState): Zone[] {
    const toHectares = (zone: Zone): Zone => ({ ...zone, size: zone.size / 10000 });

    const sumArea = (zone: Zone[]): Zone =>
      zone.map(toHectares).reduce((z1, z2) => ({ ...z1, size: z1.size + z2.size }));

    const featureToZone = (feature: Feature): Zone => {
      const rate = feature.vegetation.custom_value_q ?? feature.vegetation.value_q;
      const color = feature.properties.customColor ?? feature.properties.fill;
      const name = `${rate}`;

      return {
        name,
        rate,
        size: feature.area,
        color,
      };
    };

    const heatmaps = Object.values(state.heatmaps.current);

    const colorCodeToZone = (colorCode: ColorCode): Zone => {
      const parsedRate = Number(colorCode.name);
      return {
        name: colorCode.name,
        rate: parsedRate,
        size: 0,
        color: colorCode.col,
      };
    };

    const zonesWithoutArea = heatmaps
      .flatMap<ColorCode>((heatmap) => heatmap.color_codes)
      .filter((colorCode) => colorCode.area > 0)
      .map<Zone>(colorCodeToZone);

    const zonesWithArea = heatmaps.flatMap<Feature>((heatmap: Heatmap) => heatmap.features).map(featureToZone);

    const allZones = [...zonesWithArea, ...zonesWithoutArea];
    const zonesByHeatmaps = Object.values(groupby(allZones, 'color'))
      .map<Zone>(sumArea)
      .sort((first, second) => {
        if (first.name.includes('snow')) {
          return 1;
        }
        if (second.name.includes('snow')) {
          return -1;
        }
        if (first.name.includes('cloud')) {
          return 1;
        }
        if (second.name.includes('cloud')) {
          return -1;
        }

        if (first.rate > second.rate) {
          return -1;
        }
        return 1;
      });

    return zonesByHeatmaps;
  },
};

export default moduleGetters;
