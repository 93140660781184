<template>
  <div>
    <FormFieldSet v-if="currentCompanies.length > 1 || useCustomers" :headline="$t('Betrieb')">
      <FormFieldSelect
        v-if="currentCompanies.length > 1"
        :value="getFormFieldValue('processOrder.companyId')"
        :label="$t('Betrieb')"
        :options="companyOptions"
        :various="
          currentCompanies.length > 1 &&
          changes['processOrder.companyId'] == null &&
          !mergedFieldsInfo['processOrder.companyId']
        "
        @update="(value) => setFormFieldValue('processOrder.companyId', value)"
      />
      <FormFieldDropdown
        v-if="useCustomers"
        :label="$t('Kunde')"
        :value="customerValueForDropdown"
        :items="customersToDropdownItems"
        :items-loading="$store.state.customers.fetching"
        :various="
          fields.length > 1 && changes['fieldGroup.customerId'] == null && !mergedFieldsInfo['fieldGroup.customerId']
        "
        :disabled="fetching"
        @update="(value) => setFormFieldValue('fieldGroup.customerId', value.id)"
      />
    </FormFieldSet>
    <FormFieldSet class="form-field-set-edit-fields" :headline="fieldSetHeadline">
      <FormFieldInput
        :value="getFormFieldValue('fieldGroup.mfa')"
        :label="$t('Feldstück Nr')"
        :placeholder="$t('Feldstück Nr')"
        :various="fields.length > 1 && changes['fieldGroup.mfa'] == null && !mergedFieldsInfo['fieldGroup.mfa']"
        :disabled="fetching"
        @update="(value) => setFormFieldValue('fieldGroup.mfa', value)"
      />
      <FormFieldInput
        v-if="columnsByKey.fieldNumber != null && columnsByKey.fieldNumber.visible"
        :value="getFormFieldValue('fieldNumber')"
        :label="$t('Feld Nr')"
        :placeholder="$t('Feld Nr')"
        :various="fields.length > 1 && changes.fieldNumber == null && !mergedFieldsInfo.fieldNumber"
        :disabled="fetching"
        @update="(value) => setFormFieldValue('fieldNumber', value)"
      />
      <FormFieldInput
        required
        :value="getFormFieldValue('name')"
        :label="$t('Bezeichnung')"
        :placeholder="$t('Pflichtfeld')"
        :state="nameInputState"
        :various="fields.length > 1 && changes.name == null && !mergedFieldsInfo.name"
        :disabled="fetching"
        @update="(value) => setFormFieldValue('name', value)"
      />
      <FormFieldInput
        type="number"
        placeholder="0"
        step="0.01"
        :value="getFormFieldSize"
        :label="$t('Größe (in ha)')"
        :various="fields.length > 1 && changes.fieldSize == null && !mergedFieldsInfo.fieldSize"
        :disabled="fetching"
        @update="(value) => setFormFieldValue('fieldSize', value)"
        @blur="roundFieldSize"
      />
      <FormFieldDropdown
        ref="crop"
        :label="$t('Kultur')"
        :value="cropValueForDropdown"
        :items="cropsToDropdownItems"
        :items-loading="$store.state.products.crops.fetching"
        :various="fields.length > 1 && changes.cropId === undefined && !mergedFieldsInfo.cropId"
        :disabled="fetching"
        @update="(value) => setFormFieldValue('cropId', value.id)"
        @open="onOpenDropdown({ action: 'products/crops/subscribe', ref: 'crop' })"
      />
      <BButton v-if="!showAdditionalData" variant="link" @click="showAdditionalData = true">
        <FontAwesomeIcon :icon="['fal', 'plus-circle']" />
        {{ $t('Mehr anzeigen') }}
      </BButton>
      <div v-if="showAdditionalData" class="form-field-set-edit-fields__additional-data">
        <FormFieldDropdown
          v-if="columnsByKey.varietyId != null && columnsByKey.varietyId.visible"
          ref="variety"
          :label="$t('Sorte')"
          :value="varietyValueForDropdown"
          :items="varietiesToDropdownItems"
          :items-loading="$store.state.varieties.fetching"
          :various="fields.length > 1 && changes.varietyId === undefined && !mergedFieldsInfo.varietyId"
          :disabled="fetching"
          @update="(value) => setFormFieldValue('varietyId', value.id)"
          @open="onOpenDropdown({ action: 'varieties/subscribe', ref: 'variety' })"
        />
        <FormFieldDropdown
          v-if="columnsByKey.preCropId != null && columnsByKey.preCropId.visible"
          ref="preCrop"
          :label="$t('Vorfrucht')"
          :value="preCropValueForDropdown"
          :items="cropsToDropdownItems"
          :items-loading="$store.state.products.crops.fetching"
          :various="fields.length > 1 && changes.preCropId === undefined && !mergedFieldsInfo.preCropId"
          :disabled="fetching"
          @update="(value) => setFormFieldValue('preCropId', value.id)"
          @open="onOpenDropdown({ action: 'products/crops/subscribe', ref: 'preCrop' })"
        />
        <FormFieldDropdown
          v-if="columnsByKey.catchcropVariant != null && columnsByKey.catchcropVariant.visible"
          ref="catchcrop"
          :label="$t('Begrünung (Zwischenfrucht)')"
          :value="catchcropValueForDropdown"
          :items="catchcropDropdownItems"
          :various="fields.length > 1 && changes.catchcropVariant === undefined && !mergedFieldsInfo.catchcropVariant"
          :disabled="fetching"
          @update="(value) => setFormFieldValue('catchcropVariant', value.id)"
          @open="onOpenDropdown({ ref: 'catchcrop' })"
        />
        <FormFieldDropdown
          v-if="columnsByKey.kindofuse != null && columnsByKey.kindofuse.visible"
          ref="kindOfUse"
          :label="$t('Nutzungsart')"
          :value="kindOfUseValueForDropdown"
          :items="kindOfUseDropdownItems"
          :various="fields.length > 1 && changes.kindofuse === undefined && !mergedFieldsInfo.kindofuse"
          :disabled="fetching"
          @update="(value) => setFormFieldValue('kindofuse', value.id)"
          @open="onOpenDropdown({ ref: 'kindOfUse' })"
        />
        <FormFieldInput
          v-if="columnsByKey.comment != null && columnsByKey.comment.visible"
          :value="getFormFieldValue('comment')"
          :label="$t('Kommentar')"
          :placeholder="$t('Kommentar')"
          :various="fields.length > 1 && changes.comment == null && !mergedFieldsInfo.comment"
          :disabled="fetching"
          @update="(value) => setFormFieldValue('comment', value)"
        />
        <FormFieldDropdown
          v-if="columnsByKey.amaCode != null && columnsByKey.amaCode.visible"
          ref="amaCode"
          :label="$t('AMA Code')"
          :value="amaCodeValueForDropdown"
          :items="amaCodeDropdownItems"
          :various="fields.length > 1 && changes.amaCode === undefined && !mergedFieldsInfo.amaCode"
          :disabled="fetching"
          @update="(value) => setFormFieldValue('amaCode', value.id)"
          @open="onOpenDropdown({ ref: 'amaCode' })"
        />
        <FormFieldInput
          v-if="
            columnsByKey['additionalData.cadastralCommunity'] != null &&
            columnsByKey['additionalData.cadastralCommunity'].visible
          "
          :value="getFormFieldValue('additionalData.cadastralCommunity')"
          :label="$t('Gemeinde')"
          :placeholder="$t('Gemeinde')"
          :various="fields.length > 1 && additionalDataVarious('additionalData.cadastralCommunity')"
          :disabled="fetching"
          @update="(value) => setFormFieldValue('additionalData.cadastralCommunity', value)"
        />
        <FormFieldInput
          v-if="columnsByKey['additionalData.plots'] != null && columnsByKey['additionalData.plots'].visible"
          :value="getFormFieldValue('additionalData.plots')"
          :label="$t('Parzellen Nr.')"
          :placeholder="$t('Parzellen Nr.')"
          :various="fields.length > 1 && additionalDataVarious('additionalData.plots')"
          :disabled="fetching"
          @update="(value) => setFormFieldValue('additionalData.plots', value)"
        />
        <FormFieldInput
          v-if="columnsByKey['additionalData.owner'] != null && columnsByKey['additionalData.owner'].visible"
          :value="getFormFieldValue('additionalData.owner')"
          :label="$t('Eigentümer')"
          :placeholder="$t('Eigentümer')"
          :various="fields.length > 1 && additionalDataVarious('additionalData.owner')"
          :disabled="fetching"
          @update="(value) => setFormFieldValue('additionalData.owner', value)"
        />
        <FormFieldInput
          v-if="columnsByKey['additionalData.rightOfUse'] != null && columnsByKey['additionalData.rightOfUse'].visible"
          :value="getFormFieldValue('additionalData.rightOfUse')"
          :label="$t('Nutzung durch')"
          :placeholder="$t('Nutzung durch')"
          :various="fields.length > 1 && additionalDataVarious('additionalData.rightOfUse')"
          :disabled="fetching"
          @update="(value) => setFormFieldValue('additionalData.rightOfUse', value)"
        />
        <FormFieldDropdown
          v-if="columnsByKey.gwArea != null && columnsByKey.gwArea.visible"
          ref="gwArea"
          :label="$t('GW-Gebiet')"
          :value="gwAreaValueForDropdown"
          :items="gwAreaDropdownItems"
          :various="fields.length > 1 && changes.gwArea === undefined && !mergedFieldsInfo.gwArea"
          :disabled="fetching"
          @update="(value) => setFormFieldValue('gwArea', value.id)"
          @open="onOpenDropdown({ ref: 'gwArea' })"
        />
      </div>
    </FormFieldSet>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons';
import { mapGetters } from 'vuex';

import cropsToDropdownItems from '@/products/crops/mixins/containers/cropsToDropdownItems';
import FormFieldDropdown from '@/shared/components/form/FormFieldDropdown.vue';
import FormFieldInput from '@/shared/components/form/FormFieldInput.vue';
import FormFieldSelect from '@/shared/components/form/FormFieldSelect.vue';
import FormFieldSet from '@/shared/components/form/FormFieldSet.vue';
import { getDescendantProp } from '@/shared/modules/getDisplayValue';
import notNullOrUndefined from '@/shared/modules/notNullOrUndefinedFilter';
import objectsMatchingProperties from '@/shared/modules/objectsMatchingProperties';
import varietiesToDropdownItems from '@/varieties/mixins/containers/varietiesToDropdownItems';

import dropdownItemsForFields, {
  STORE_KEY_AMA_CODES,
  STORE_KEY_CATCH_CROP_VARIANTS,
  STORE_KEY_GW_AREAS,
  STORE_KEY_KIND_OF_USE_TYPES,
} from '../mixins/containers/dropdownItemsForFields';
import fieldSizeDecimals from '../mixins/fieldSizeDecimals';

library.add(faPlusCircle);

export default {
  name: 'FormFieldSetEditFields',
  components: { FormFieldDropdown, FormFieldInput, FormFieldSet, FormFieldSelect },
  mixins: [cropsToDropdownItems, varietiesToDropdownItems, dropdownItemsForFields, fieldSizeDecimals],
  model: {
    prop: 'changes',
    event: 'update',
  },
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    changes: {
      type: Object,
      required: true,
    },
    columns: {
      type: Array,
      default: null,
      validator(columns) {
        if (columns == null) {
          return true;
        }
        if (!Array.isArray(columns)) {
          return false;
        }
        return columns.every(
          (column) => column != null && typeof column.key === 'string' && typeof column.visible === 'boolean',
        );
      },
    },
    missingName: {
      type: Boolean,
      default: false,
    },
    uniqueNameViolation: {
      type: Boolean,
      default: false,
    },
    fetching: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mergedFieldsData: {},
      mergedFieldsInfo: {},
      showAdditionalData: false,
    };
  },
  async created() {
    const fieldsMatchingProps = objectsMatchingProperties(this.fields);
    this.mergedFieldsData = fieldsMatchingProps.result;
    this.mergedFieldsInfo = fieldsMatchingProps.matchingInfo;
  },
  computed: {
    ...mapGetters({
      customers: 'customers/data',
    }),
    nameInputState() {
      if (this.missingName || this.uniqueNameViolation) {
        return false;
      }

      return null;
    },
    currentCompanies() {
      return this.$store.state.auth.currentCompanies;
    },
    sgdVersion() {
      return (
        this.$store.getters['auth/currentProcessOrders'].map((order) => order.sgdVersion).find(notNullOrUndefined) ?? ''
      );
    },
    companyOptions() {
      return [
        { value: null, text: this.$t('Betrieb wählen'), disabled: true },
        ...this.currentCompanies.map((company) => ({ value: company.id, text: company.name })),
      ];
    },
    columnsByKey() {
      if (this.columns == null) {
        return {};
      }
      return this.columns.reduce(
        (columnsByKey, column) => ({
          ...columnsByKey,
          [column.key]: column,
        }),
        {},
      );
    },
    fieldSetHeadline() {
      if (typeof this.changes.name === 'string' && this.changes.name.length > 0) {
        return this.changes.name;
      }
      return this.fields
        .filter((field) => typeof field.name === 'string' && field.name.length > 0)
        .map((field) => field.name)
        .join(', ');
    },
    getFormFieldSize() {
      if (this.changes.fieldSize != null) {
        return this.changes.fieldSize;
      }
      return this.fieldSizeDecimals(getDescendantProp(this.mergedFieldsData, 'fieldSize'));
    },
    cropValueForDropdown() {
      if (this.changes.cropId != null && this.crops[this.changes.cropId] != null) {
        return { id: this.changes.cropId, name: this.crops[this.changes.cropId].name };
      }
      if (Object.prototype.hasOwnProperty.call(this.changes, 'cropId')) {
        return { id: null, name: '' };
      }
      if (this.mergedFieldsInfo.cropId && this.crops[this.mergedFieldsData.cropId] != null) {
        return { id: this.mergedFieldsData.cropId, name: this.crops[this.mergedFieldsData.cropId].name };
      }
      return { id: null, name: '' };
    },
    varietyValueForDropdown() {
      if (this.changes.varietyId != null && this.varieties[this.changes.varietyId] != null) {
        return { id: this.changes.varietyId, name: this.varieties[this.changes.varietyId].name };
      }
      if (Object.prototype.hasOwnProperty.call(this.changes, 'varietyId')) {
        return { id: null, name: '' };
      }
      if (this.mergedFieldsInfo.varietyId && this.varieties[this.mergedFieldsData.varietyId] != null) {
        return { id: this.mergedFieldsData.varietyId, name: this.varieties[this.mergedFieldsData.varietyId].name };
      }
      return { id: null, name: '' };
    },
    preCropValueForDropdown() {
      if (this.changes.preCropId != null && this.crops[this.changes.preCropId] != null) {
        return { id: this.changes.preCropId, name: this.crops[this.changes.preCropId].name };
      }
      if (Object.prototype.hasOwnProperty.call(this.changes, 'preCropId')) {
        return { id: null, name: '' };
      }
      if (this.mergedFieldsInfo.preCropId && this.crops[this.mergedFieldsData.preCropId] != null) {
        return { id: this.mergedFieldsData.preCropId, name: this.crops[this.mergedFieldsData.preCropId].name };
      }
      return { id: null, name: '' };
    },
    useCustomers() {
      if (this.customers == null) {
        return false;
      }
      return Object.keys(this.customers).length > 0;
    },
    customerValueForDropdown() {
      let customerId = null;
      if (this.changes['fieldGroup.customerId'] != null) {
        customerId = this.changes['fieldGroup.customerId'];
      } else if (
        this.mergedFieldsInfo['fieldGroup.customerId'] &&
        this.mergedFieldsData['fieldGroup.customerId'] != null
      ) {
        customerId = this.mergedFieldsData['fieldGroup.customerId'];
      } else if (this.mergedFieldsInfo['fieldGroup.customerId']) {
        customerId = 'own';
      }
      if (this.customers[customerId] != null) {
        return {
          id: customerId,
          name: this.customers[customerId].name,
        };
      }
      if (customerId === 'own') {
        return { id: 'own', name: this.$t('Eigener Betrieb') };
      }
      return { id: null, name: '' };
    },
    customersToDropdownItems() {
      return [
        {
          id: 'own-company',
          items: [{ id: 'own', name: this.$t('Eigener Betrieb') }],
          sort: false,
        },
        {
          name: this.$t('Kunden'),
          id: 'customers',
          items: Object.values(this.customers),
          sort: true,
        },
      ];
    },
    catchcropValueForDropdown() {
      if (this.changes.catchcropVariant != null && this.catchCropVariantsById[this.changes.catchcropVariant] != null) {
        return this.catchCropVariantsById[this.changes.catchcropVariant];
      }
      if (
        this.mergedFieldsInfo.catchcropVariant &&
        this.catchCropVariantsById[this.mergedFieldsData.catchcropVariant] != null
      ) {
        return this.catchCropVariantsById[this.mergedFieldsData.catchcropVariant];
      }
      return { id: null, name: '' };
    },
    catchcropDropdownItems() {
      return this.dropdownItemsForFieldsGetItems(STORE_KEY_CATCH_CROP_VARIANTS);
    },
    kindOfUseValueForDropdown() {
      if (this.changes.kindofuse != null && this.kindOfUseTypesById[this.changes.kindofuse] != null) {
        return this.kindOfUseTypesById[this.changes.kindofuse];
      }
      if (this.mergedFieldsInfo.kindofuse && this.kindOfUseTypesById[this.mergedFieldsData.kindofuse] != null) {
        return this.kindOfUseTypesById[this.mergedFieldsData.kindofuse];
      }
      return { id: null, name: '' };
    },
    kindOfUseDropdownItems() {
      return this.dropdownItemsForFieldsGetItems(STORE_KEY_KIND_OF_USE_TYPES);
    },
    amaCodeValueForDropdown() {
      if (this.changes.amaCode != null && this.amaCodesById[this.changes.amaCode] != null) {
        return this.amaCodesById[this.changes.amaCode];
      }
      if (this.mergedFieldsInfo.amaCode && this.amaCodesById[this.mergedFieldsData.amaCode] != null) {
        return this.amaCodesById[this.mergedFieldsData.amaCode];
      }
      return { id: null, name: '' };
    },
    amaCodeDropdownItems() {
      return this.dropdownItemsForFieldsGetItems(STORE_KEY_AMA_CODES);
    },
    gwAreaValueForDropdown() {
      if (this.changes.gwArea != null && this.gwAreasById[this.changes.gwArea] != null) {
        return this.gwAreasById[this.changes.gwArea];
      }
      if (this.mergedFieldsInfo.gwArea && this.gwAreasById[this.mergedFieldsData.gwArea] != null) {
        return this.gwAreasById[this.mergedFieldsData.gwArea];
      }
      return { id: null, name: '' };
    },
    gwAreaDropdownItems() {
      return this.dropdownItemsForFieldsGetItems(STORE_KEY_GW_AREAS);
    },
  },
  methods: {
    getFormFieldValue(key) {
      if (this.changes[key] != null) {
        return this.changes[key];
      }
      if (getDescendantProp(this.mergedFieldsData, key) != null) {
        return getDescendantProp(this.mergedFieldsData, key);
      }
      return null;
    },
    setFormFieldValue(key, value) {
      this.$emit('update', {
        ...this.changes,
        [key]: value,
      });
    },
    roundFieldSize() {
      if (this.changes.fieldSize == null) {
        return;
      }
      this.$emit('update', {
        ...this.changes,
        fieldSize: this.fieldSizeDecimals(this.changes.fieldSize),
      });
    },
    onOpenDropdown({ action, ref }) {
      if (typeof action === 'string') {
        this.$store.dispatch(action);
      }

      /**
       * WORKAROUND
       * Currently the Dropdown is placed absolute in place and therefore is cut off if the container has overflow: hidden.
       * Until the opened dropdown is placed outside the container (or refactored into BDropdown) scroll until the full dropdown is visible.
       */
      this.$emit('openDropdown', this.$refs[ref].$el);
    },
    additionalDataVarious(key) {
      if (this.changes[key] != null) {
        return false;
      }
      return getDescendantProp(this.mergedFieldsInfo, key) === false;
    },
  },
};
</script>

<style scoped>
.form-field-set-edit-fields__additional-data {
  margin-top: var(--spacer_5);
}
</style>
