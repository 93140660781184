import Vue from 'vue';
import isMulticompany from '@/auth/isMulticompany';
import usedItems from '@/shared/mixins/store/usedItems';
import { subscribableStore } from './common';
import { filterMulticompany, findSiblingsOfActivityType, syncName } from './compatibleActivityTypesFilter';
const moduleGetters = Object.assign(Object.assign(Object.assign({}, subscribableStore.getters), usedItems.getters), { byRoughAndFineId: (state) => (roughId, fineId = null) => {
        const activityType = Object.values(state.data).find((currentActivityType) => currentActivityType.roughId === roughId && currentActivityType.fineId === fineId);
        return activityType !== null && activityType !== void 0 ? activityType : null;
    }, allActivityTypes: (state) => Object.values(state.data), activityTypeByType: (state) => (type) => Object.values(state.data).find((activityType) => activityType.type === type), compatibleActivityTypes: (state, getters) => (companies) => {
        if (isMulticompany(companies)) {
            return getters.allActivityTypes.filter((activityType) => {
                const activityTypeSiblings = findSiblingsOfActivityType(activityType, getters.allActivityTypes);
                return filterMulticompany(activityType, activityTypeSiblings, companies);
            });
        }
        return Object.values(state.data);
    }, compatibleActivityTypesWithoutSyncNameDuplicates: (state, getters) => (companies) => {
        const compatibleActivityTypes = getters.compatibleActivityTypes(companies);
        const bySyncName = new Map();
        compatibleActivityTypes.forEach((activityType) => {
            bySyncName.set(syncName(activityType), activityType);
        });
        return Array.from(bySyncName.values());
    }, dropdownItem: (state) => (activityTypeId) => {
        const activityType = state.data[activityTypeId];
        if (!activityType)
            return null;
        return {
            id: activityType.id,
            name: activityType.displayName,
        };
    }, dropdownItems: (state, getters) => (companies) => {
        const activityTypes = getters.compatibleActivityTypesWithoutSyncNameDuplicates(companies);
        const usedActivityTypes = getters.used;
        const items = activityTypes.map((activityType) => ({
            id: activityType.id,
            name: activityType.displayName,
        }));
        const lastUsed = {
            name: Vue.i18n.translate('Zuletzt verwendet') || '',
            id: 'activityTypes-used',
            items: items.filter((item) => usedActivityTypes.includes(item.id || '')),
            sort: false,
        };
        const others = {
            name: Vue.i18n.translate('Tätigkeiten') || '',
            id: 'activityTypes',
            items: items.filter((item) => !usedActivityTypes.includes(item.id || '')),
            sort: true,
        };
        return [lastUsed, others];
    }, findActivityTypeOfCompany: (state, getters) => (activityTypeId, companyId) => {
        const candidate = state.data[activityTypeId];
        if (!candidate)
            return undefined;
        if (candidate.companyId === companyId)
            return candidate;
        const activityTypeSiblings = findSiblingsOfActivityType(candidate, getters.allActivityTypes);
        return activityTypeSiblings.find((activityType) => activityType.companyId === companyId);
    }, findActivityTypeOfCompanyBySyncName: (state) => (name, companyId) => Object.values(state.data).find((candidate) => syncName(candidate) === name && candidate.companyId === companyId), getActivityTypeOfCompanyBySyncName: (state, getters) => (name, companyId) => {
        const activityType = getters.findActivityTypeOfCompanyBySyncName(name, companyId);
        if (!activityType)
            throw new Error(`ActivityType with syncName ${name} not found`);
        return activityType;
    }, findBySyncName: (state) => (name) => Object.values(state.data).find((candidate) => syncName(candidate) === name), getBySyncName: (state, getters) => (name) => {
        const activityType = getters.findBySyncName(name);
        if (!activityType)
            throw new Error(`ActivityType with syncName ${name} not found`);
        return activityType;
    }, activityTypeDisplayName: (state) => (id) => {
        const activityType = state.data[id];
        if (!activityType)
            return '';
        return activityType.displayName || '';
    } });
export default moduleGetters;
