import axios, { CancelTokenSource } from 'axios';
import debounce from 'lodash.debounce';
import moment from 'moment';
import Vue from 'vue';
import { ActionContext, ActionTree } from 'vuex';

import { MATERIAL_CATEGORY_ID_MINERAL_FERTILIZER, UNIT_ID_KG_PER_HA } from '@/products/constants';
import { GUID_KEY } from '@/shared/constants';
import { RootState } from '@/store/types';

import baseWorkflowStore from '../../store/baseWorkflowStore';
import { POLYGON_STATES, ZONE_GENERATION_MODE_UPLOAD } from '../../store/baseWorkflowStore/common';
import { Zone } from '../../store/baseWorkflowStore/types/Zone';
import { ApplicationMapsFertilizationState, NutrientDosage } from './types';

let multiPolySource: CancelTokenSource | null = null;

export type ActionPayloads = {
  setManualDosage: { key: keyof NutrientDosage; index: number; value: number };
};

const actions: ActionTree<ApplicationMapsFertilizationState, RootState> = {
  ...baseWorkflowStore.actions,
  // satellite images
  async setMultipolyTimestamp({ state, commit, getters }, polygonKey: string) {
    const polygon = state.polygons[polygonKey];
    const requestIdentifier = `${polygonKey}:${getters.coverageRatio}`;
    const multiPolyFromPolygon = state.multiPolyTimestamps.loaded[requestIdentifier];
    let multiPolyTimestamp = {};

    if (multiPolyFromPolygon) {
      multiPolyTimestamp = {
        key: requestIdentifier,
        data: multiPolyFromPolygon,
      };
    } else {
      const { coverageRatio } = getters;
      const timeStart = moment().startOf('day').subtract(1, 'year').unix();
      const timeEnd = moment().startOf('day').unix();
      const { data } = await axios.post('/admin/sen4/multiPolyTimestamps', {
        polygon: polygon.pathsForAxios,
        timeStart,
        timeEnd,
        coverageRatio,
      });
      multiPolyTimestamp = {
        key: requestIdentifier,
        data,
      };
      commit('addMultipolyTimestamp', multiPolyTimestamp);
    }

    // as we introduced to use a combination of the fieldId and the coverageRatio as key,
    // the current timestamp won't be correctly set if the field stays the same and the
    // coverageRatio changes, therefore we delete all timestamps of the field, regardless of the coverageRatio
    // before setting the new currentTimestamp if it is the same field.
    if (
      Object.keys(state.multiPolyTimestamps.current)
        .map((key) => key.split(':')[0])
        .some((fieldId) => fieldId === polygonKey)
    ) {
      commit('unsetCurrentMultipolyTimestamp', `${polygonKey}:0`);
      commit('unsetCurrentMultipolyTimestamp', `${polygonKey}:2`);
    }

    commit('setCurrentMultipolyTimestamp', multiPolyTimestamp);

    const { timeArray } = getters.availableTimestamps;
    for (let i = 0; i < timeArray.length; i += 1) {
      if (state.selectedHeatmapTimestamp === timeArray[i]) {
        commit('setHeatmapTimestampSelectedIndex', i);
        break;
      }
    }
  },
  async loadHeatmaps(params) {
    await doLoadHeatmaps(params);
  },

  // fields
  async polygonSetState({ commit, dispatch }, { key, state: polygonState }) {
    commit('polygonSetState', { key, state: polygonState });
    if (polygonState === POLYGON_STATES.ACTIVE) {
      await dispatch('setMultipolyTimestamp', key);
    } else if (polygonState === POLYGON_STATES.INACTIVE) {
      commit('unsetCurrentMultipolyTimestamp', key);
    }
  },

  // dosage calculation
  setMaterial({ rootState, commit }, material) {
    commit('setMaterial', material);
    if (material.id === 'manual') {
      return;
    }
    const { n, p, k } = rootState.products.mineralFertilizers.data[material.id];
    commit('setCalculationField', { key: 'n', value: n });
    commit('setCalculationField', { key: 'p', value: p });
    commit('setCalculationField', { key: 'k', value: k });
  },
  setCalculationField({ commit }, data) {
    commit('setCalculationField', data);
    if (['n', 'p', 'k'].includes(data.key)) {
      commit('setMaterial', { id: 'manual', name: Vue.i18n.translate('Eigener Dünger') });
    }
  },
  setManualDosage({ state, commit, getters }, { key, index, value }: ActionPayloads['setManualDosage']) {
    let currentDosage = null;
    if (state.calculation.manualDosage == null) {
      currentDosage = getters.zoneDosage.map((zone: Zone) => ({ ...zone }));
    } else {
      currentDosage = state.calculation.manualDosage.map((zone) => ({ ...zone }));
    }
    let dosage = value;
    if (key !== 'dosage' && state.calculation[key] !== 0) {
      dosage = value / state.calculation[key];
    }
    currentDosage[index].dosage = dosage;
    currentDosage[index].n = state.calculation.n * dosage;
    currentDosage[index].p = state.calculation.p * dosage;
    currentDosage[index].k = state.calculation.k * dosage;
    commit('setManualDosage', currentDosage);
  },
  // task
  async setTask({ commit, rootState, dispatch, state }, task) {
    await dispatch('auth/subscribe', undefined, { root: true });
    await Promise.all([
      dispatch('fields/subscribe', undefined, { root: true }),
      dispatch('products/mineralFertilizers/subscribe', undefined, { root: true }),
    ]);
    commit('setTask', task);

    // set zoneGenerationMode
    if (
      task.applicationMap != null &&
      task.applicationMap.additionalData != null &&
      task.applicationMap.additionalData.zoneGenerationMode === ZONE_GENERATION_MODE_UPLOAD
    ) {
      commit('setZoneGenerationMode', ZONE_GENERATION_MODE_UPLOAD);
    }

    // select fields
    const selectedFields: string[] = [];
    if (
      task.applicationMap != null &&
      task.applicationMap.additionalData != null &&
      Array.isArray(task.applicationMap.additionalData.fields)
    ) {
      // @ts-ignore // TODO fix this
      task.applicationMap.additionalData.fields.forEach(({ [GUID_KEY]: guid }) => {
        if (rootState.fields.data[guid] != null) {
          selectedFields.push(guid);
        }
      });
    } else if (Array.isArray(task.fields)) {
      // @ts-ignore // TODO fix this
      task.fields.forEach((taskField) => {
        const { guid } = taskField.field;
        if (rootState.fields.data[guid] != null) {
          selectedFields.push(guid);
        }
      });
    }
    commit('setSelectedFields', selectedFields);
    if (
      task.applicationMap != null &&
      task.applicationMap.additionalData != null &&
      task.applicationMap.additionalData.selectedIndexType != null
    ) {
      commit('setSelectedIndexType', task.applicationMap.additionalData.selectedIndexType);
    }
    if (
      task.applicationMap != null &&
      task.applicationMap.additionalData != null &&
      task.applicationMap.additionalData.selectedHeatmapTimestamp != null
    ) {
      commit('setHeatmapTimestamp', task.applicationMap.additionalData.selectedHeatmapTimestamp);
    }
    if (
      task.applicationMap != null &&
      task.applicationMap.additionalData != null &&
      task.applicationMap.additionalData.selectedQuantisationCode != null
    ) {
      commit('setSelectedQuantisationCode', task.applicationMap.additionalData.selectedQuantisationCode);
    }
    if (task.applicationMap != null && task.applicationMap.geoJson != null) {
      if (
        task.applicationMap.additionalData != null &&
        task.applicationMap.additionalData.zoneGenerationMode === ZONE_GENERATION_MODE_UPLOAD
      ) {
        commit('setUploadedZonesByFilename', task.applicationMap.geoJson);
      } else {
        commit('setHeatmaps', task.applicationMap.geoJson);
        const multiPolyRequestIdentifier = [
          ...state.selectedFields,
          state.selectedIndexType,
          state.selectedQuantisationCode,
        ].join('_');
        commit('setMultiPolyRequestIdentifier', multiPolyRequestIdentifier);
      }
    }

    // set pagination to step2 if fields are selected
    commit('setPaginationStep', 2);

    // set calculation values
    if (
      task.applicationMap != null &&
      task.applicationMap.additionalData != null &&
      task.applicationMap.additionalData.calculation != null &&
      task.applicationMap.additionalData.calculation.manualDosage != null
    ) {
      commit('setManualDosage', task.applicationMap.additionalData.calculation.manualDosage);
    }
    if (
      task.applicationMap != null &&
      task.applicationMap.additionalData != null &&
      task.applicationMap.additionalData.calculation != null &&
      typeof task.applicationMap.additionalData.calculation.n === 'number'
    ) {
      commit('setCalculationField', {
        key: 'n',
        value: task.applicationMap.additionalData.calculation.n,
      });
    }
    if (
      task.applicationMap != null &&
      task.applicationMap.additionalData != null &&
      task.applicationMap.additionalData.calculation != null &&
      typeof task.applicationMap.additionalData.calculation.p === 'number'
    ) {
      commit('setCalculationField', {
        key: 'p',
        value: task.applicationMap.additionalData.calculation.p,
      });
    }
    if (
      task.applicationMap != null &&
      task.applicationMap.additionalData != null &&
      task.applicationMap.additionalData.calculation != null &&
      typeof task.applicationMap.additionalData.calculation.k === 'number'
    ) {
      commit('setCalculationField', {
        key: 'k',
        value: task.applicationMap.additionalData.calculation.k,
      });
    }

    // set product
    if (
      task.applicationMap != null &&
      task.applicationMap.additionalData != null &&
      task.applicationMap.additionalData.calculation != null &&
      typeof task.applicationMap.additionalData.calculation.averageDosage === 'number' &&
      task.applicationMap.additionalData.calculation.averageDosage > 0
    ) {
      commit('setCalculationField', {
        key: 'averageDosage',
        value: task.applicationMap.additionalData.calculation.averageDosage,
      });
    }
    if (
      task.applicationMap != null &&
      task.applicationMap.additionalData != null &&
      task.applicationMap.additionalData.calculation != null &&
      task.applicationMap.additionalData.calculation.material != null
    ) {
      commit('setMaterial', task.applicationMap.additionalData.calculation.material);
    } else if (Array.isArray(task.workingMeans) && task.workingMeans.length > 0) {
      // @ts-ignore // TODO fix this
      task.workingMeans.some((taskMaterial) => {
        if (
          taskMaterial != null &&
          taskMaterial.workingMean != null &&
          taskMaterial.workingMean.guid != null &&
          typeof taskMaterial.workingMean.name === 'string' &&
          taskMaterial.workingMean.category != null &&
          taskMaterial.workingMean.category.guid === MATERIAL_CATEGORY_ID_MINERAL_FERTILIZER &&
          typeof taskMaterial.amount === 'number' &&
          taskMaterial.unit != null &&
          taskMaterial.unit.guid === UNIT_ID_KG_PER_HA
        ) {
          commit('setMaterial', {
            id: taskMaterial.workingMean.guid,
            name: taskMaterial.workingMean.name,
          });
          commit('setCalculationField', {
            key: 'averageDosage',
            value: taskMaterial.amount,
          });
          commit('setCalculationField', {
            key: 'n',
            value: rootState.products.mineralFertilizers.data[taskMaterial.workingMean.guid].n,
          });
          commit('setCalculationField', {
            key: 'p',
            value: rootState.products.mineralFertilizers.data[taskMaterial.workingMean.guid].p,
          });
          commit('setCalculationField', {
            key: 'k',
            value: rootState.products.mineralFertilizers.data[taskMaterial.workingMean.guid].k,
          });
          return true;
        }
        return false;
      });
    }
  },
};

const doLoadHeatmaps = debounce(
  async ({ state, commit, getters }: ActionContext<ApplicationMapsFertilizationState, RootState>): Promise<void> => {
    if (multiPolySource != null) {
      multiPolySource.cancel();
    }

    const {
      selectedFields,
      selectedHeatmapTimestamp: satelliteTimestamp,
      polygons,
      selectedIndexType,
      selectedQuantisationCode,
      heatmaps,
      multiPolyTimestamps,
    } = state;

    const productIds: string[] = selectedFields
      .filter((guid: string) => !!polygons[guid])
      .filter((guid: string) => {
        const clientId = getters.toClientId(guid);
        return !heatmaps.current[clientId];
      });

    const satelliteMoment = moment.unix(satelliteTimestamp);
    const products = productIds.map((guid: string) => {
      const polygon = polygons[guid].pathsForAxios;
      const key = `${guid}:${getters.coverageRatio}`;
      const dbId =
        multiPolyTimestamps.loaded[key]?.availableData?.find((ts: { dbId: string; timestamp: number }) =>
          moment.unix(ts.timestamp).isSame(satelliteMoment, 'day'),
        )?.dbId ?? '';

      const clientId = getters.toClientId(guid);
      const fieldKey = guid;
      return selectedIndexType.includes('DNN_')
        ? {
            timestamp: satelliteTimestamp,
            polygon,
            clientId,
            fieldKey,
          }
        : {
            dbId,
            polygon,
            clientId,
            fieldKey,
          };
    });
    if (!products.length) {
      return;
    }

    multiPolySource = axios.CancelToken.source();
    let data: any = null;
    state.heatmaps.fetching = true;
    try {
      ({ data } = await axios.post(
        '/admin/sen4/multiPoly',
        {
          products,
          indexType: selectedIndexType,
          configSet: 'SET_A1',
          quantisationCode: selectedQuantisationCode,
        },
        { cancelToken: multiPolySource.token },
      ));
    } catch (e) {
      return;
    } finally {
      state.heatmaps.fetching = false;
    }
    multiPolySource = null;

    const newHeatmaps = {
      ...data.products,
    };

    commit('setHeatmaps', newHeatmaps);
  },
  100,
  { leading: false, trailing: true },
);

export default actions;
