
import { type PropType, defineComponent } from 'vue';

import FormZones from '@/precision-farming/application-maps/containers/FormZones.vue';
import TableZonesContainer from '@/precision-farming/application-maps/containers/TableZonesContainer.vue';
import type { ZoneGenerationMode } from '@/precision-farming/application-maps/store/baseWorkflowStore/types';
import type { ApplicationMapsState } from '@/precision-farming/application-maps/store/types';
import SidebarContentWrapper from '@/shared/components/SidebarContentWrapper.vue';
import SidebarHeadline from '@/shared/components/SidebarHeadline.vue';
import SidebarSubHeadline from '@/shared/components/SidebarSubHeadline.vue';
import Tabs from '@/shared/components/Tabs.vue';
import { Tab } from '@/shared/components/tabsTypes';

import { ZONE_GENERATION_MODE_SATELLITE, ZONE_GENERATION_MODE_UPLOAD } from '../store/baseWorkflowStore/common';
import FormUploadZoneFiles from './FormUploadZoneFiles.vue';

export default defineComponent({
  name: 'SidebarZones',
  components: {
    FormZones,
    SidebarContentWrapper,
    SidebarHeadline,
    SidebarSubHeadline,
    Tabs,
    FormUploadZoneFiles,
    TableZonesContainer,
  },
  props: {
    workflowKey: {
      type: String as PropType<keyof ApplicationMapsState>,
      required: true,
    },
  },
  computed: {
    zoneGenerationMode(): ZoneGenerationMode {
      return this.$store.state.precisionFarming.applicationMaps[this.workflowKey].zoneGenerationMode;
    },
    buttonSatellites(): Tab {
      return {
        type: 'button',
        label: this.$t('Satellit') || 'Satellit',
        active: this.zoneGenerationMode === ZONE_GENERATION_MODE_SATELLITE,
        onClick: () => this.setZoneGenerationMode(ZONE_GENERATION_MODE_SATELLITE),
      };
    },
    buttonUpload(): Tab {
      return {
        type: 'button',
        label: this.$t('Upload') || 'Upload',
        active: this.zoneGenerationMode === ZONE_GENERATION_MODE_UPLOAD,
        onClick: () => this.setZoneGenerationMode(ZONE_GENERATION_MODE_UPLOAD),
      };
    },
  },
  methods: {
    setZoneGenerationMode(mode: ZoneGenerationMode): void {
      this.$store.commit(`precisionFarming/applicationMaps/${this.workflowKey}/setZoneGenerationMode`, mode);
    },
  },
});
