/**
 * This function can be used to filter duplicate items in stream or array. This filter performs equality by-value, not by reference. You can overwrite the comparator to change the equality behavior. E.g. comparison by reference, or by a specific key or property
 * https://stackoverflow.com/questions/43118692/typescript-filter-out-nulls-from-an-array
 */
export default function isUnique<T>(
  value: T,
  index: number,
  array: T[],
  comparator: (o1: T, o2: T) => boolean = (o1, o2) => JSON.stringify(o1) === JSON.stringify(o2),
): boolean {
  const foundIndex = array.findIndex((v: T) => comparator(value, v));
  return foundIndex === index || foundIndex === -1;
}
