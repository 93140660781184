import Vue from 'vue';
import { Dispatch, GetterTree } from 'vuex';

import { Zone } from '@/precision-farming/application-maps/store/baseWorkflowStore/types/Zone';
import { ACTIVITY_ROUGH_GUIDS } from '@/shared/constants';
import notNullOrUndefined from '@/shared/modules/notNullOrUndefinedFilter';
import { RootState } from '@/store/types';

import baseWorkflowStore from '../../store/baseWorkflowStore';
import { Getters as BaseWorkflowGetters } from '../../store/baseWorkflowStore/getters';
import { Feature } from '../../store/baseWorkflowStore/types/Heatmap';
import { WorkflowKeyForRoutes } from '../../types';
import {
  applyLossCompensation,
  calcSeedRateOfZone,
  calcVarietyValueOfZone,
  getManualSeedRateOfZone,
} from './getterUtils';
import { ApplicationMapsSeedingState, Calculation, SeedingZone, WorkflowVarieties, ZoneDosage } from './types';

export type Getters = BaseWorkflowGetters & {
  paginationNextDisabledReason: string;
  varieties: WorkflowVarieties;
  calculation: Calculation;
  features: Feature[];
  zones: SeedingZone[];
  zonesByHeatmap: Zone[];
  zoneDosage: ZoneDosage[];
  zonesMinSeedrate: number;
  zonesMaxSeedrate: number;
  taskDataAsync: (dispatch: Dispatch) => Promise<unknown>;
};

const moduleGetters: GetterTree<ApplicationMapsSeedingState, RootState> = {
  ...baseWorkflowStore.getters,
  paginationNextEnabled: (state) => {
    if (state.selectedFields.length === 0) {
      return false;
    }
    if (state.paginationStep <= 1) {
      return true;
    }
    if (state.paginationStep === 2 && !state.heatmaps.fetching && state.calculation.variety.id) {
      return true;
    }

    if (state.paginationStep === 3 && state.stepsCompleted) {
      return true;
    }

    return false;
  },
  paginationNextDisabledReason: (state, getters) => {
    if (getters.paginationNextEnabled) {
      return null;
    }

    switch (state.paginationStep) {
      case 1:
        return Vue.i18n.translate('Bitte w\u00e4hle ein Feld aus, bevor du fortfährst.');
      case 2:
        return Vue.i18n.translate('Bitte w\u00e4hle eine Sorte aus, bevor du fortfährst.');
      default:
        return null;
    }
  },
  varieties: (state) => state.varieties,
  calculation: (state) => state.calculation,
  features(state) {
    let features: Feature[] = [];
    Object.values(state.heatmaps.current).forEach((heatmap) => {
      features = [...features, ...heatmap.features];
    });
    return features;
  },
  zones: (state, getters: Getters) =>
    getters.zonesByHeatmaps.map((zone: Zone) => {
      const varietyValue = calcVarietyValueOfZone(zone, state.calculation);
      const seedRate = calcSeedRateOfZone(varietyValue, state.calculation);
      const seedRateWithLossCompensation = applyLossCompensation(seedRate, state.calculation);
      const manualSeedRate = getManualSeedRateOfZone(zone.name, state.calculation);

      return {
        ...zone,
        varietyValue,
        seedRate,
        seedRateWithLossCompensation,
        manualSeedRate,
      };
    }),
  zoneDosage(state, getters: Getters) {
    return getters.zones.map((zone) => ({
      color: zone.color,
      name: zone.name,
      dosage: zone.manualSeedRate ?? zone.seedRateWithLossCompensation,
    }));
  },
  zonesMinSeedrate: (state, getters: Getters) => {
    const seedRates = getters.zones.map((zone) => zone.seedRate).filter(notNullOrUndefined);
    return Math.min(...seedRates);
  },
  zonesMaxSeedrate: (state, getters: Getters) => {
    const seedRates = getters.zones.map((zone) => zone.seedRate).filter(notNullOrUndefined);
    return Math.max(...seedRates);
  },
  taskDataAsync: (state, getters, rootState, rootGetters) => async (dispatch: Dispatch) => {
    // TODO taskDataAsync is somehow redundant with state.task -> remove this method and adapt and use state.task
    await dispatch('activityTypes/subscribe', {}, { root: true });

    const { currentHeatmaps, zoneDosage, selectedTaskDate } = getters;
    const {
      zoneGenerationMode,
      selectedFields,
      selectedIndexType,
      selectedHeatmapDbId,
      selectedHeatmapTimestamp,
      selectedQuantisationCode,
      calculation,
      task,
      selectedCompany,
    } = state;

    const companyId = selectedCompany.id ? selectedCompany.id : rootState.auth.currentCompanies[0].id;

    const materials = [];
    if (
      calculation.material.id != null &&
      calculation.material.id !== 'manual' &&
      typeof calculation.averageDosage === 'number' &&
      calculation.averageDosage > 0
    ) {
      materials.push({
        workingMeanId: calculation.material.id,
        amount: calculation.averageDosage,
        unitId: calculation.unit,
      });
    }

    const processOrder = await rootGetters['auth/processOrderByCompanyIdAndNameAndTypeAsync'](
      rootState.auth.currentCompanies[0].id,
      rootState.auth.currentProcessOrderName,
      'service',
    );

    const timeStartDate = selectedTaskDate || new Date();
    const timeStart = Math.floor(timeStartDate.getTime() / 1000);

    function appendToTaskComment(comment: string) {
      let userComment = '';
      if (task?.userComment) {
        userComment = `${task.userComment} `;
      }
      userComment = `${userComment}${comment}`;
      return userComment;
    }
    const userComment = appendToTaskComment(calculation.variety.name);

    const taskData = {
      version: '2.0',
      data: {
        companyId,
        processOrderId: processOrder.id,
        activityId: rootGetters['activityTypes/byRoughAndFineId'](ACTIVITY_ROUGH_GUIDS.SEED_MAIN).id,
        timeStart,
        state: 'planned',
        fields: selectedFields.map((guid) => ({
          fieldId: guid,
          processedArea: rootGetters.fields[guid].fieldSize,
        })),
        workingMeans: materials,
        applicationMap: {
          additionalData: {
            fields: selectedFields.map((guid) => {
              const field = {
                id: guid,
                geoJsonId: Object.keys(currentHeatmaps).find((heatmapId) => heatmapId.startsWith(guid)) || null,
              };

              return field;
            }),
            zoneGenerationMode,
            selectedIndexType, // TODO is not initialised
            selectedHeatmapDbId,
            selectedHeatmapTimestamp, // TODO is not initialised
            selectedQuantisationCode, // TODO is not initialised
            calculation,
            zoneDosage,
          },
          geoJson: currentHeatmaps,
          companyId,
          workflowKey: WorkflowKeyForRoutes.SEEDING,
        },
        userComment,
      },
    };
    return taskData;
  },
};

export default moduleGetters;
