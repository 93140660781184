import initialStateSubscribable from '@/shared/mixins/store/subscribableData/initialState';
import { subscribableStore } from './common';
import getters from './getters';
export function initialState() {
    return Object.assign({}, initialStateSubscribable());
}
const vuexModule = {
    namespaced: true,
    state: initialState(),
    mutations: Object.assign({}, subscribableStore.mutations),
    actions: Object.assign({}, subscribableStore.actions),
    getters,
};
export default vuexModule;
