
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

import numbro from '@/initNumbro';
import Export from '@/precision-farming/application-maps/components/export/Export.vue';
import ExportTable, { type Row } from '@/precision-farming/application-maps/components/export/ExportTable.vue';
import type { Getters } from '@/precision-farming/application-maps/spraying/store/getters';
import type {
  ApplicationMapsSprayingState,
  Calculation,
  PlantProtection,
  Zone,
} from '@/precision-farming/application-maps/spraying/store/types';
import {
  type ColorCode,
  type Heatmap,
} from '@/precision-farming/application-maps/store/baseWorkflowStore/types/Heatmap';
import type { Field } from '@/shared/api/rest/models';
import notNullOrUndefined from '@/shared/modules/notNullOrUndefinedFilter';

export default defineComponent({
  name: 'SidebarExportSpraying',
  components: {
    Export,
    ExportTable,
  },
  data(): { numbro: typeof numbro } {
    return {
      numbro,
    };
  },
  computed: {
    ...mapState('fields', {
      fields: 'data',
    }),
    ...mapState('precisionFarming/applicationMaps/spraying', {
      selectedFieldIds(state: ApplicationMapsSprayingState): string[] {
        return state.selectedFields;
      },
      calculation(state: ApplicationMapsSprayingState): Calculation {
        return state.calculation;
      },
      reducedVegetationPerZone(state: ApplicationMapsSprayingState, getters: Getters): number[] {
        return getters.reducedVegetationPerZone;
      },
      heatmaps(state: ApplicationMapsSprayingState, getters: Getters): Heatmap[] {
        return getters.heatmapsOfSelectedFields;
      },
      zones(state: ApplicationMapsSprayingState, getters: Getters): Zone[] {
        return getters.zones;
      },
    }),
    selectedFields(): Field[] {
      return this.selectedFieldIds.map<Field>((fieldId: string) => this.fields[fieldId]).filter(notNullOrUndefined);
    },
    tableHeaders(): string[] {
      const productNames = this.calculation.products.map((p) => p.product.name);
      return [this.$t('Spritzbrühe') ?? '', ...productNames];
    },
    totalArea(): number {
      return this.selectedFields.map((field) => field.fieldSize).reduce((a, b) => a + b, 0);
    },
    vegetationMax(): number {
      return Math.max(...this.reducedVegetationPerZone);
    },
    /**
     * Product reduction matrix per zone from result table in step 03, where rows constitute the zones, and the columns the sprayed products
     */
    productReductionsPerZone(): number[][] {
      return this.zones.map((_, zoneIndex) =>
        this.calculation.products.map((protection: PlantProtection) =>
          this.reduceProduct(protection.amount, zoneIndex),
        ),
      );
    },
    tableRows(): Row[] {
      return this.heatmaps.map<Row>((fieldHeatmap: Heatmap, fieldIndex: number) => {
        const targetField = this.selectedFields[fieldIndex];

        const sprayMixMid = (this.calculation.sprayMix * targetField.fieldSize) / this.totalArea;

        const productMids: number[] = this.calculation.products.map<number>((_: PlantProtection, productIndex) =>
          this.productMid(fieldHeatmap, productIndex),
        );

        return {
          title: targetField.name,
          name: targetField.name,
          values: [numbro(sprayMixMid).format(), ...productMids.map((productMid) => numbro(productMid).format())],
        };
      });
    },
  },
  methods: {
    reduceProduct(productAmount: number, zoneIndex: number): number {
      return (productAmount * this.reducedVegetationPerZone[zoneIndex]) / this.vegetationMax;
    },
    /**
     * Returns the reduced product amount per zone, by zone name and product Index. Observe rows and columns from the results table in step 03
     * @param zoneColor
     * @param productIndex
     */
    productReductionMatrix(zoneColor: string, productIndex: number): number {
      const zoneIndex = this.zones.findIndex((zone) => zone.color === zoneColor);
      return this.productReductionsPerZone[zoneIndex][productIndex];
    },
    /**
     * For given field (heatmap), matches every subpolygon (cluster) with the reduced product amount from the matrix, and calculates the product mid
     * for that field
     * @param fieldHeatmap
     * @param productIndex
     */
    productMid(fieldHeatmap: Heatmap, productIndex: number): number {
      const productSum = fieldHeatmap.color_codes
        .filter((cluster: ColorCode) => cluster.area > 0)
        .map((cluster: ColorCode) => {
          const productReduction = this.productReductionMatrix(cluster.col, productIndex);
          return productReduction * cluster.area;
        })
        .reduce((a, b) => a + b, 0);
      return productSum / fieldHeatmap.area;
    },
  },
});
