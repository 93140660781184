import initialStateSubscribable from '@/shared/mixins/store/subscribableData/initialState';
import { initialState as initialStateUsedItems } from '@/shared/mixins/store/usedItems';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
export function initialState() {
    return Object.assign(Object.assign({}, initialStateSubscribable()), initialStateUsedItems());
}
const vuexModule = {
    namespaced: true,
    state: initialState(),
    mutations,
    actions,
    getters,
};
export default vuexModule;
