var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import chunk from 'lodash.chunk';
import Vue from 'vue';
import loadAllPages from '@/shared/api/farmdokRestApi/utils/loadAllPages';
import mergeApiResponses from '@/shared/api/farmdokRestApi/utils/mergeApiResponses';
import removableData from '@/shared/mixins/store/removableData/index';
import subscribableData from '@/shared/mixins/store/subscribableData/index';
import syncableData from '@/shared/mixins/store/syncableData/index';
import ActivitySyncService from '../services/ActivitySyncService';
export function fetchAll(context, axiosOptions) {
    return __awaiter(this, void 0, void 0, function* () {
        const processOrderIds = context.rootGetters['auth/currentProcessOrderIds'];
        const emptyFakeResponse = {
            version: '2.0',
            status: 'success',
            data: [],
        };
        if ((processOrderIds === null || processOrderIds === void 0 ? void 0 : processOrderIds.length) === 0) {
            return emptyFakeResponse;
        }
        const { activitiesApi } = Vue.prototype.$api;
        const apiResponses = yield Promise.all(processOrderIds.map((processOrderId) => {
            const requestParameters = { processOrderId, deleted: false };
            return loadAllPages(activitiesApi, 'activityList', requestParameters, axiosOptions);
        }));
        const apiResponse = mergeApiResponses(apiResponses);
        return apiResponse;
    });
}
function fetchByIds(ids, context, axiosOptions) {
    return __awaiter(this, void 0, void 0, function* () {
        const { activitiesApi } = Vue.prototype.$api;
        const { data } = yield activitiesApi.activityObjects({ objectIds: ids.join(',') }, axiosOptions);
        return data;
    });
}
function updateEntries(entries, 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
context) {
    return __awaiter(this, void 0, void 0, function* () {
        const syncableActivities = ActivitySyncService.toSyncableActivities(entries);
        const { activitiesApi } = Vue.prototype.$api;
        const { data } = yield activitiesApi.activityPut({
            activityPutRequest: { data: syncableActivities },
        });
        return data;
    });
}
export function patchEntry(entry) {
    return __awaiter(this, void 0, void 0, function* () {
        const { activitiesApi } = Vue.prototype.$api;
        const { data } = yield activitiesApi.activityPatch({
            activityPatchRequest: { data: [entry] },
        });
        return data;
    });
}
function toApiResponse(response) {
    return Object.assign(Object.assign({}, response), { data: response.data || [] });
}
function removeEntries(entries) {
    return __awaiter(this, void 0, void 0, function* () {
        const { activitiesApi } = Vue.prototype.$api;
        const activityIds = entries.map((entry) => entry.id);
        const activityIdsInBlocks = chunk(activityIds, 100);
        const promises = activityIdsInBlocks.map((block) => activitiesApi
            .activityDelete({ objectIds: block.join(',') })
            .then((response) => response.data)
            .then((response) => toApiResponse(response)));
        const responses = yield Promise.all(promises);
        return mergeApiResponses(responses);
    });
}
export const subscribableStore = subscribableData(fetchAll, fetchByIds);
export const syncableStore = syncableData(updateEntries);
export const removableStore = removableData(removeEntries);
// TODO restorableData
