import applicationMaps from '../application-maps/store';
import monitoring from '../monitoring/store';
const vuexModule = {
    namespaced: true,
    state: {},
    modules: {
        applicationMaps,
        monitoring,
    },
};
export default vuexModule;
