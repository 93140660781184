<template>
  <PageApplicationMaps :workflowKey="workflowKey" :stepCount="3" @onNavigationNext="onNavigationNext">
    <template #headerLogo>
      <HeaderLogo :src="headerLogo.src" :alt="headerLogo.alt" />
    </template>
    <template #steps>
      <SidebarFields
        tableId="sidebar-table-fields-seeding"
        v-show="paginationStep === 1"
        :selected-rows.sync="selectedFields"
        :customColumnDefinition="columnDefinition"
      />
      <SidebarCreateApplicationMapSeeding ref="createApplicationMap" v-if="paginationStep === 2" />
      <SidebarExportSeeding v-show="paginationStep === 3" :workflowKey="workflowKey" />
      <ModalConfirmation :messages="messages" v-model="visible" @reset="reset" @ignore="stepForward()" />
    </template>
  </PageApplicationMaps>
</template>
<script>
import { mapGetters, mapState } from 'vuex';

import SidebarFields from '@/fields/components/SidebarFields.vue';
import ModalConfirmation from '@/precision-farming/application-maps/components/ModalConfirmation.vue';
import { WorkflowKey } from '@/precision-farming/application-maps/types';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

import columnsSidebarTableFields from '../../../fields/handsontable/columns/applicationMapsSidebarTableFields';
import PageApplicationMaps from '../PageApplicationMaps.vue';
import HeaderLogo from '../components/HeaderLogo.vue';
import SidebarCreateApplicationMapSeeding from './SidebarCreateApplicationMapSeeding.vue';
import SidebarExportSeeding from './SidebarExportSeeding.vue';

export default {
  name: 'PageApplicationMapsSeeding',
  components: {
    PageApplicationMaps,
    SidebarFields,
    HeaderLogo,
    SidebarCreateApplicationMapSeeding,
    SidebarExportSeeding,
    ModalConfirmation,
  },
  data() {
    return {
      workflowKey: WorkflowKey.SEEDING,
      headerLogo: {
        src: 'https://develop.farmdok.com/assets/img/contracting/saatbau_Logo_Sondervariante_Kompakt_72dpi_RGB_144.png',
        alt: 'Saatbau Logo',
      },
      columnDefinition: columnsSidebarTableFields,
      visible: false,
      stepForward: () => {},
    };
  },
  computed: {
    ...mapState('precisionFarming/applicationMaps/seeding', ['paginationStep']),
    ...mapGetters({
      zones: 'precisionFarming/applicationMaps/seeding/zones',
    }),

    currentCompaniesHaveFeature() {
      return this.$store.getters.currentCompaniesHaveFeature(availableFeatures.FEATURE_APPLICATION_MAPS_SAATBAU_MAIZE);
    },

    selectedFields: {
      get() {
        return this.$store.state.precisionFarming.applicationMaps.seeding.selectedFields;
      },
      set(selected) {
        this.$store.commit('precisionFarming/applicationMaps/seeding/setSelectedFields', selected);
      },
    },

    seedRatesExceedThresholds() {
      const lowerThreshold = 50000;
      const upperThreshold = 150000;
      if (
        this.zones.some(
          (zone) => zone.seedRate < lowerThreshold || (zone.manualSeedRate && zone.manualSeedRate < lowerThreshold),
        )
      ) {
        return 'lower';
      }
      if (
        this.zones.some(
          (zone) => zone.seedRate > upperThreshold || (zone.manualSeedRate && zone.manualSeedRate > upperThreshold),
        )
      ) {
        return 'upper';
      }
      return null;
    },
    messages() {
      const messages = [];

      const comparativeForm = this.$t(this.seedRatesExceedThresholds === 'upper' ? 'höher' : 'niedriger');
      const part1 = this.$t('Die Saatstärke ist in einer oder mehreren Zonen beträchtlich {comparative} als üblich.', {
        comparative: comparativeForm,
      });
      const part2 = this.$t(
        'Möchtest Du mit diesen Werten fortsetzten oder die Einstellungen auf die empfohlenen Werte zurücksetzen?',
      );

      messages.push(`${part1} ${part2}`);

      return messages;
    },
  },

  methods: {
    async onNavigationNext(stepForward) {
      this.stepForward = stepForward;
      if (this.paginationStep === 2 && this.seedRatesExceedThresholds) {
        this.visible = true;
        return;
      }

      stepForward();
    },
    reset() {
      this.$store.dispatch(`precisionFarming/applicationMaps/seeding/resetManualSeedrates`);
    },
  },
};
</script>
