import actions from './actions';
import getters from './getters';
import initialState from './initialState';
import mutations from './mutations';
export default function subscribableData(fetchAll, fetchByIds, requireFeatures = []) {
    return {
        namespaced: true,
        state: initialState(),
        mutations,
        actions: actions(fetchAll, fetchByIds, requireFeatures),
        getters,
    };
}
