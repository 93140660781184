import moment from 'moment';
import { mapGetters, mapState } from 'vuex';

import { ZONE_GENERATION_MODE_SATELLITE, ZONE_GENERATION_MODE_UPLOAD } from '../store/baseWorkflowStore/common';

function isEmpty(value) {
  return value == null || value === 0 || value === '' || value === '0';
}

export default {
  computed: {
    ...mapGetters({
      fields: 'fields',
    }),
    ...mapState({
      zoneDosage(state, getters) {
        return getters[`precisionFarming/applicationMaps/${this.workflowKey}/zoneDosage`];
      },
      zones(state, getters) {
        return getters[`precisionFarming/applicationMaps/${this.workflowKey}/zones`];
      },
      selectedFields(state) {
        return state.precisionFarming.applicationMaps[this.workflowKey].selectedFields;
      },
      currentHeatmaps(state, getters) {
        return getters[`precisionFarming/applicationMaps/${this.workflowKey}/currentHeatmaps`];
      },
      uploadedZonesByFilename(state, getters) {
        return getters[`precisionFarming/applicationMaps/${this.workflowKey}/uploadedZonesByFilename`];
      },
      zoneGenerationMode(state) {
        return state.precisionFarming.applicationMaps[this.workflowKey].zoneGenerationMode;
      },
    }),
  },
  methods: {
    getFieldName(field) {
      let fieldName = '';
      if (!isEmpty(field.fieldGroup.mfa)) {
        fieldName = `${field.fieldGroup.mfa}_`;
      }
      if (!isEmpty(field.fieldNumber)) {
        fieldName += `${field.fieldNumber}_`;
      }
      fieldName += `${field.name}_`;
      return fieldName;
    },
    getApplicationMapName() {
      const guid = this.selectedFields[0];
      const field = this.fields[guid];
      let filename = this.getFieldName(field);
      if (this.selectedFields.length > 1) {
        filename += 'MULTI_';
      }
      filename += `${moment().format('YYYY-MM-DD')}`;

      return filename;
    },
    getFeatures(selectedShapeType) {
      let featuresByFieldName = {};
      if (this.zoneGenerationMode === ZONE_GENERATION_MODE_SATELLITE) {
        featuresByFieldName = this.getFeaturesFromHeatmaps();
      } else if (this.zoneGenerationMode === ZONE_GENERATION_MODE_UPLOAD) {
        featuresByFieldName = this.getFeaturesFromUploadedZones();
      }
      return Object.keys(featuresByFieldName).reduce(
        (acc, fieldName) => ({
          ...acc,
          [fieldName]: featuresByFieldName[fieldName].map((feature) => {
            const mappedFeature = {
              geometry: feature.geometry,
              type: feature.type,
              properties: {},
            };
            if (selectedShapeType === 'amazone') {
              mappedFeature.properties[fieldName] = this.getDosageByColor(
                feature.properties.customColor ?? feature.properties.fill,
              );
            } else {
              mappedFeature.properties.RATE = this.getDosageByColor(
                feature.properties.customColor ?? feature.properties.fill,
              );
            }
            return mappedFeature;
          }),
        }),
        {},
      );
    },
    getFeaturesFromHeatmaps() {
      return Object.keys(this.currentHeatmaps).reduce((acc, curr) => {
        const guid = curr.split('_')[0];
        const field = this.fields[guid];
        let fieldName = this.getFieldName(field);
        fieldName = fieldName.substr(0, fieldName.length - 1);
        return {
          ...acc,
          [fieldName]: this.currentHeatmaps[curr].features,
        };
      }, {});
    },
    getFeaturesFromUploadedZones() {
      return Object.keys(this.uploadedZonesByFilename).reduce(
        (acc, curr) => ({
          ...acc,
          [curr]: this.uploadedZonesByFilename[curr].features,
        }),
        {},
      );
    },
    async applicationMapAsShape(selectedShapeType, filename = this.getApplicationMapName()) {
      const { write } = await import('shp-write-prj');
      const { default: JSZip } = await import('jszip');

      const zip = new JSZip();
      let folder = zip;
      if (selectedShapeType === 'johndeere') {
        folder = zip.folder('Rx');
      } else if (selectedShapeType === 'trimble') {
        folder = zip.folder('AgGPS').folder('Prescriptions');
      }

      const featuresByFieldName = this.getFeatures(selectedShapeType);
      Object.keys(featuresByFieldName).forEach((fieldName) => {
        const features = featuresByFieldName[fieldName];

        // add dummy projection
        folder.file(
          `${fieldName}.prj`,
          'GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137,298.257223563]],PRIMEM["Greenwich",0],UNIT["Degree",0.017453292519943295]]',
        );

        const data = features.map((feature) => feature.properties);
        const coordinates = features.map((feature) => feature.geometry.coordinates);
        const coordinatesInverted = this.invertContour(coordinates); // shp-write-prj inverts coordinates, therefore we need to invert them before writing

        write(data, 'POLYGON', coordinatesInverted, (err, files) => {
          folder.file(`${fieldName}.shp`, files.shp.buffer, { binary: true });
          folder.file(`${fieldName}.shx`, files.shx.buffer, { binary: true });
          folder.file(`${fieldName}.dbf`, files.dbf.buffer, { binary: true });
        });
      });

      const content = await zip.generateAsync({
        compression: 'STORE',
        type: 'blob',
      });
      return {
        file: content,
        filename: `${filename}.zip`,
      };
    },
    getDosageByColor(color) {
      const zone = (this.zoneDosage ?? this.zones).find((zoneCandidate) => zoneCandidate.color === color);
      return zone.dosage ?? zone.rate ?? null;
    },
    invertContour(coordinates) {
      return coordinates.map((c) => c.map((ring) => ring.reverse()));
    },
  },
};
