var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import debounce from 'lodash.debounce';
import Vue from 'vue';
import { dataToBase64 } from '@/shared/api/rest/requestUtils';
import { ENTRY_ERROR_UPDATING, ENTRY_NEW, ENTRY_REMOVING, ENTRY_TO_REMOVE, ERROR_UPDATING } from '@/shared/constants';
import initialStateSubscribable from '@/shared/mixins/store/subscribableData/initialState';
import { getRestResponseData, mergeRestResponseData } from '@/shared/modules/restApiHelpers';
/**
 * @deprecated use removableData module instead
 * @returns
 */
export function initialState() {
    return Object.assign(Object.assign({}, initialStateSubscribable()), { removing: false, removableObservers: [] });
}
/**
 * Returns a store that can update store data + sync those changes to the backend.
 *
 * Add resolve like in subscribableData to prevent sending resolves objects on update.
 *
 * @param {string} dataUrl
 * @returns {object}
 */
export default function syncableData(dataUrl) {
    return {
        namespaced: true,
        state: initialState(),
        mutations: {
            /**
             * Call this before sending (bulk) delete to the BE.
             * Update the storeStatus for all given entries to ENTRY_REMOVING.
             * Additionally set state.removing to true.
             * Removes errors for all entries that get deleted.
             *
             * @param state
             * @param entries
             */
            syncAllRemovesStart(state, entries) {
                Vue.set(state, 'removing', true);
                Vue.set(state, 'data', entries.reduce((currentState, entry) => {
                    if (currentState[entry.id] == null) {
                        return currentState;
                    }
                    return Object.assign(Object.assign({}, currentState), { [entry.id]: Object.assign(Object.assign({}, currentState[entry.id]), { storeStatus: ENTRY_REMOVING }) });
                }, state.data));
                const entryIds = entries.map(({ id }) => id);
                Vue.set(state, 'errors', state.errors.filter((error) => !entryIds.includes(error.id)));
            },
            /**
             * Call this after successful (bulk) delete.
             * Updates all given entries in the store.
             * Additionally set state.syncing to false.
             *
             * @param state
             * @param entries
             */
            syncAllRemovesFinish(state, entries) {
                if (!state.removing) {
                    return;
                }
                Vue.set(state, 'removing', false);
                if (!Array.isArray(entries)) {
                    return;
                }
                const data = Object.assign({}, state.data);
                entries.forEach(({ id }) => {
                    delete data[id];
                });
                Vue.set(state, 'data', data);
            },
            /**
             * Add a callback that is called as soon as all entries are synced or an error occurred.
             *
             * @param state
             * @param callback
             */
            addRemovableObserver(state, callback) {
                Vue.set(state, 'removableObservers', [...state.removableObservers, callback]);
            },
            /**
             * Resolve and clear all current callbacks. Call this after all entries are synced or an error occurred.
             *
             * @param state
             */
            clearRemovableObservers(state) {
                if (state.removableObservers && state.removableObservers.length > 0) {
                    state.removableObservers.forEach((callback) => callback());
                }
                Vue.set(state, 'removableObservers', []);
            },
            /**
             * Set an entry to 'removed' if it's new or 'to_remove' otherwise.
             * 'to_remove' entries have to be synced to the BE.
             *
             * @param state
             * @param entry
             */
            removeEntry(state, { entry }) {
                if (state.data[entry.id] == null) {
                    return;
                }
                if (state.data[entry.id].storeStatus === ENTRY_NEW) {
                    const data = Object.assign({}, state.data);
                    delete data[entry.id];
                    Vue.set(state, 'data', data);
                }
                else {
                    Vue.set(state, 'data', Object.assign(Object.assign({}, state.data), { [entry.id]: Object.assign(Object.assign({}, state.data[entry.id]), { storeStatus: ENTRY_TO_REMOVE }) }));
                }
            },
            /**
             * Add error into store.
             *
             * @param state
             * @param guid
             * @param key
             * @param errorUserMessage
             */
            addRemovalError(state, { guid, key, errorUserMessage }) {
                Vue.set(state, 'data', Object.assign(Object.assign({}, state.data), { [guid]: Object.assign(Object.assign({}, state.data[guid]), { storeStatus: ENTRY_ERROR_UPDATING }) }));
                Vue.set(state, 'errors', [
                    ...state.errors,
                    {
                        type: ERROR_UPDATING,
                        guid,
                        key,
                        errorUserMessage,
                    },
                ]);
            },
        },
        actions: {
            /**
             * Updates the entry storeStatus and then sends delete request to BE.
             *
             * @param commit
             * @param dispatch
             * @param entry
             * @return {Promise<void>}
             */
            removeAndSyncEntry({ commit, dispatch }, { entry }) {
                return __awaiter(this, void 0, void 0, function* () {
                    commit('removeEntry', { entry });
                    yield dispatch('syncAllRemovals');
                });
            },
            /**
             * Send delete request to BE for all objects.
             */
            syncAllRemovals: debounce(({ state, commit, dispatch, }) => __awaiter(this, void 0, void 0, function* () {
                var _a;
                if (state.removing) {
                    // eslint-disable-next-line no-promise-executor-return
                    yield new Promise((callback) => commit('addRemovableObserver', callback));
                    return;
                }
                const entries = Object.values(state.data).filter((entry) => entry.storeStatus === ENTRY_TO_REMOVE);
                if (entries.length < 1) {
                    commit('clearRemovableObservers');
                    return;
                }
                commit('syncAllRemovesStart', entries);
                let responseData;
                try {
                    const responses = [];
                    yield Promise.all([...new Array(Math.ceil(entries.length / 50)).keys()].map((index) => __awaiter(this, void 0, void 0, function* () {
                        const start = index * 50;
                        const end = (index + 1) * 50;
                        const filter = ['id', 'IN', entries.slice(start, end).map(({ id }) => id)];
                        const { data } = yield axios.delete(`${dataUrl}?version=2.0&filter=${dataToBase64(filter)}`);
                        responses.push(getRestResponseData(data));
                    })));
                    responseData = mergeRestResponseData(...responses);
                }
                catch (error) {
                    responseData = getRestResponseData(error);
                }
                if ((responseData === null || responseData === void 0 ? void 0 : responseData.status) === 'success') {
                    commit('syncAllRemovesFinish', responseData.data);
                    yield dispatch('syncAllRemovals');
                }
                else if ((responseData === null || responseData === void 0 ? void 0 : responseData.status) === 'partialSuccess') {
                    dispatch('addRemoveErrors', { entries, responseData });
                    commit('syncAllRemovesFinish', 
                    // @ts-ignore
                    (_a = responseData.data) === null || _a === void 0 ? void 0 : _a.filter((entry) => { var _a; return ((_a = responseData === null || responseData === void 0 ? void 0 : responseData.errors) === null || _a === void 0 ? void 0 : _a[entry.id]) == null; }));
                    yield dispatch('syncAllRemovals');
                }
                else {
                    dispatch('addRemoveErrors', { entries, responseData });
                    commit('clearRemovableObservers');
                    commit('syncAllRemovesFinish');
                }
            }), 100),
            /**
             * Adds dummy error objects for every entry that was to be removed if the whole request failed.
             * In case of a 'partialSuccess' errors will be added for every entry that failed.
             *
             * @param commit
             * @param entries
             * @param responseData
             */
            addRemoveErrors({ commit }, { entries, responseData }) {
                if (responseData.status !== 'partialSuccess') {
                    entries.forEach((entry) => {
                        commit('addRemovalError', {
                            guid: entry.id,
                            key: null,
                            errorUserMessage: responseData.errorUserMessage[0],
                        });
                    });
                    return;
                }
                Object.keys(responseData.errors || {}).forEach((guid) => {
                    // @ts-ignore
                    const error = getRestResponseData(responseData.errors[guid]);
                    commit('addRemovalError', {
                        guid,
                        key: null,
                        errorUserMessage: error.errorUserMessage[0],
                    });
                });
            },
        },
    };
}
