
import { type PropType, defineComponent } from 'vue';

import FormFieldDropdown from '@/shared/components/form/FormFieldDropdown.vue';
import { type DropdownItem, DropdownItemsPerCategory } from '@/shared/components/form/formFieldDropdownTypes';

export default defineComponent({
  name: 'ControlSatelliteIndex',
  components: { FormFieldDropdown },
  props: {
    selectedIndex: {
      type: Object as PropType<DropdownItem<{ id: string; name: string }>>,
      required: false,
      default: () => ({ id: '', name: '' }),
    },
    indexTypes: {
      type: Array as PropType<DropdownItemsPerCategory[]>,
      required: true,
      default: () => [],
    },
  },
  emits: ['update:selectedIndex'],
  model: {
    prop: 'selectedIndex',
    event: 'update:selectedIndex',
  },
});
