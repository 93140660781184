import Vue from 'vue';
import { MutationTree } from 'vuex';

import type { IndexType } from '@/precision-farming/application-maps/store/baseWorkflowStore/types';

import baseWorkflowStore from '../../store/baseWorkflowStore';
import initialState from './initialState';
import { ApplicationMapsFertilizationState } from './types';

const mutations: MutationTree<ApplicationMapsFertilizationState> = {
  ...baseWorkflowStore.mutations,
  reset(state) {
    const newState = initialState();
    Object.entries(newState).forEach(([key, value]) => {
      Vue.set(state, key, value);
    });
  },

  // satellite images
  addMultipolyTimestamp(state, timestamp) {
    const newMultiPolyTimestamps = { ...state.multiPolyTimestamps.loaded };

    newMultiPolyTimestamps[timestamp.key] = timestamp.data;
    Vue.set(state.multiPolyTimestamps, 'loaded', newMultiPolyTimestamps);
  },
  setCurrentMultipolyTimestamp(state, timestamp) {
    const newMultiPolyTimestamps = { ...state.multiPolyTimestamps.current };

    newMultiPolyTimestamps[timestamp.key] = timestamp.data;
    Vue.set(state.multiPolyTimestamps, 'current', newMultiPolyTimestamps);
  },
  unsetCurrentMultipolyTimestamp(state: ApplicationMapsFertilizationState, polygonKey: string) {
    const newMultiPolyTimestamps = { ...state.multiPolyTimestamps.current };

    delete newMultiPolyTimestamps[polygonKey];
    Vue.set(state.multiPolyTimestamps, 'current', newMultiPolyTimestamps);
  },

  setHeatmapTimestampSelectedIndex(state: ApplicationMapsFertilizationState, value) {
    Vue.set(state, 'heatmapTimestampSelectedIndex', value);
  },
  setHeatmapTimestampManuallySelected(state, value) {
    Vue.set(state, 'heatmapTimestampManuallySelected', value);
  },
  setSelectedIndexType(state: ApplicationMapsFertilizationState, indexType: IndexType) {
    Vue.set(state, 'selectedIndexType', indexType);
  },
  setSelectedQuantisationCode(state, quantisationCode) {
    Vue.set(state, 'selectedQuantisationCode', quantisationCode);
  },

  // uploaded zones
  setUploadedZonesByFilename(state, uploadedZonesByFilename) {
    Vue.set(state, 'uploadedZonesByFilename', uploadedZonesByFilename);
  },
  uploadedZoneFilesAddGeoJson(state, { filename, geoJson }) {
    const uploadedZonesByFilename = {
      ...state.uploadedZonesByFilename,
      [filename]: geoJson,
    };
    Vue.set(state, 'uploadedZonesByFilename', uploadedZonesByFilename);
  },
  uploadedZoneFilesRemoveGeoJson(state: ApplicationMapsFertilizationState, filename) {
    const uploadedZonesByFilename = Object.keys(state.uploadedZonesByFilename).reduce((acc, currentFilename) => {
      if (currentFilename === filename) {
        return acc;
      }
      return {
        ...acc,
        [currentFilename]: state.uploadedZonesByFilename[currentFilename],
      };
    }, {});
    Vue.set(state, 'uploadedZonesByFilename', uploadedZonesByFilename);
  },
  // dosage calculation
  setMaterial(state: ApplicationMapsFertilizationState, material) {
    Vue.set(state.calculation, 'material', material);
  },
  setCalculationField(state, data) {
    Vue.set(state.calculation, data.key, data.value);
  },
  setManualDosage(state: ApplicationMapsFertilizationState, currentDosage) {
    Vue.set(state.calculation, 'manualDosage', currentDosage);
  },
};

export default mutations;
