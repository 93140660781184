import moment from 'moment';

import { ZONE_GENERATION_MODE_SATELLITE } from './common';
import { BaseWorkflowStoreModuleState } from './types';

export default function initialState(): BaseWorkflowStoreModuleState {
  return {
    paginationStep: 1,
    zoneGenerationMode: ZONE_GENERATION_MODE_SATELLITE,
    paginationCallbackAsync: null,

    selectedFields: [],
    polygons: {},

    heatmaps: {
      fetching: false,
      current: {},
    },

    fetchingTask: false,
    task: null,
    selectedTaskDate: null,
    selectedCompany: {
      id: null,
      name: '',
    },
    selectedIndexType: 'DNN_NDVI',
    selectedHeatmapTimestamp: moment().startOf('day').unix(), // set to today

    multiPolyRequestIdentifier: null,

    stepsCompleted: false,

    fetchingMaterial: false,

    calculation: {
      material: {
        id: null,
        name: '',
      },
      unit: null,
    },
    checkingViolation: false,
  };
}
