
import { defineComponent } from 'vue';
import { mapGetters, mapMutations, mapState } from 'vuex';

import SidebarFields from '@/fields/components/SidebarFields.vue';
import SidebarZones from '@/precision-farming/application-maps/components/SidebarZones.vue';
import { WorkflowKey } from '@/precision-farming/application-maps/types';

import columnsSidebarTableFields from '../../../fields/handsontable/columns/applicationMapsSidebarTableFields';
import PageApplicationMaps from '../PageApplicationMaps.vue';
import SidebarCreateApplicationMapFertilization from './SidebarCreateApplicationMapFertilization.vue';
import SidebarExportFertilization from './SidebarExportFertilization.vue';

export default defineComponent({
  name: 'PageApplicationMapsFertilization',
  components: {
    PageApplicationMaps,
    SidebarCreateApplicationMapFertilization,
    SidebarZones,
    SidebarFields,
    SidebarExportFertilization,
  },
  data(): {
    workflowKey: WorkflowKey;
    columnDefinition: typeof columnsSidebarTableFields;
  } {
    return {
      workflowKey: WorkflowKey.FERTILIZATION,
      columnDefinition: columnsSidebarTableFields,
    };
  },
  computed: {
    ...mapState('precisionFarming/applicationMaps/fertilization', ['paginationStep']),
    ...mapGetters({
      zones: 'precisionFarming/applicationMaps/fertilization/zones',
    }),
    selectedFields: {
      get() {
        return this.$store.state.precisionFarming.applicationMaps.fertilization.selectedFields;
      },
      set(selected) {
        this.$store.commit('precisionFarming/applicationMaps/fertilization/setSelectedFields', selected);
      },
    },
  },
  watch: {
    zones() {
      this.setManualDosage(null);
    },
  },
  methods: {
    ...mapMutations('precisionFarming/applicationMaps/fertilization', ['setManualDosage']),
    onNavigationNext(callBack: () => void) {
      callBack();
    },
  },
});
