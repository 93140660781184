import type { ActivityRuleViolation } from 'farmdok-rest-api';
import Vue from 'vue';
import { MutationTree } from 'vuex';

import type { IndexType } from '@/precision-farming/application-maps/store/baseWorkflowStore/types';

import baseWorkflowStore from '../../store/baseWorkflowStore';
import initialState from './initialState';
import { ApplicationMapsSprayingState, type PlantProtection, SummaryData, type WorkingMean } from './types';

const mutations: MutationTree<ApplicationMapsSprayingState> = {
  ...baseWorkflowStore.mutations,
  reset(state) {
    const newState = initialState();
    Object.entries(newState).forEach(([key, value]) => {
      Vue.set(state, key, value);
    });
  },

  // satellite images
  addMultipolyTimestamp(state, timestamp) {
    const newMultiPolyTimestamps = { ...state.multiPolyTimestamps.loaded };

    newMultiPolyTimestamps[timestamp.key] = timestamp.data;
    Vue.set(state.multiPolyTimestamps, 'loaded', newMultiPolyTimestamps);
  },
  setCurrentMultipolyTimestamp(state, timestamp) {
    const newMultiPolyTimestamps = { ...state.multiPolyTimestamps.current };

    newMultiPolyTimestamps[timestamp.key] = timestamp.data;
    Vue.set(state.multiPolyTimestamps, 'current', newMultiPolyTimestamps);
  },
  unsetCurrentMultipolyTimestamp(state: ApplicationMapsSprayingState, polygonKey) {
    const newMultiPolyTimestamps = { ...state.multiPolyTimestamps.current };

    delete newMultiPolyTimestamps[polygonKey];
    Vue.set(state.multiPolyTimestamps, 'current', newMultiPolyTimestamps);
  },

  setSelectedIndexType(state: ApplicationMapsSprayingState, indexType: IndexType) {
    Vue.set(state, 'selectedIndexType', indexType);
  },
  setSelectedQuantisationCode(state, quantisationCode) {
    Vue.set(state, 'selectedQuantisationCode', quantisationCode);
  },
  setViolations(state, violations: ActivityRuleViolation[]) {
    Vue.set(state, 'violations', violations);
  },
  setCheckingViolation(state, value: boolean) {
    Vue.set(state, 'checkingViolation', value);
  },
  setWorkingMeans(state: ApplicationMapsSprayingState, workingMeans: WorkingMean[]) {
    Vue.set(state, 'workingMeans', workingMeans);
  },
  // uploaded zones
  setUploadedZonesByFilename(state, uploadedZonesByFilename) {
    Vue.set(state, 'uploadedZonesByFilename', uploadedZonesByFilename);
  },
  uploadedZoneFilesAddGeoJson(state, { filename, geoJson }) {
    const uploadedZonesByFilename = {
      ...state.uploadedZonesByFilename,
      [filename]: geoJson,
    };
    Vue.set(state, 'uploadedZonesByFilename', uploadedZonesByFilename);
  },
  uploadedZoneFilesRemoveGeoJson(state: ApplicationMapsSprayingState, filename) {
    const uploadedZonesByFilename = Object.keys(state.uploadedZonesByFilename).reduce((acc, currentFilename) => {
      if (currentFilename === filename) {
        return acc;
      }
      return {
        ...acc,
        [currentFilename]: state.uploadedZonesByFilename[currentFilename],
      };
    }, {});
    Vue.set(state, 'uploadedZonesByFilename', uploadedZonesByFilename);
  },
  setOverwrite(state: ApplicationMapsSprayingState, overwrite: SummaryData): void {
    state.calculation.overwrite = overwrite;
  },
  setSprayMix(state: ApplicationMapsSprayingState, value: number): void {
    state.calculation.sprayMix = value;
  },
  setReduction(state: ApplicationMapsSprayingState, value: number): void {
    state.calculation.reduction = value;
  },
  setPlantProtections(state: ApplicationMapsSprayingState, protections: PlantProtection[]): void {
    state.calculation.products = protections;
  },
};

export default mutations;
