
import Vue, { type PropType, defineComponent } from 'vue';

import ModalWrapper from '@/shared/components/ModalWrapper.vue';
import Button from '@/shared/components/buttons/Button.vue';

const t = Vue.i18n.translate;

export default defineComponent({
  components: { Button, ModalWrapper },
  name: 'ModalConfirmation',
  emits: ['hide', 'ignore'],
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: `${t('Achtung')}!`,
    },
    primaryText: {
      type: String,
      required: false,
      default: t('Zurücksetzen'),
    },
    secondaryText: {
      type: String,
      required: false,
      default: t('Weiter'),
    },
    messages: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },
  },
  model: {
    prop: 'visible',
    event: 'hide',
  },
  methods: {
    ignore(): void {
      this.$emit('hide', false);
      this.$emit('ignore');
    },
  },
});
