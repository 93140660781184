
import type { ActivityRuleViolation, Rule } from 'farmdok-rest-api';
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

import ModalRuleCheckViolations from '@/precision-farming/application-maps/components/ModalRuleCheckViolations.vue';
import notNullOrUndefined from '@/shared/modules/notNullOrUndefinedFilter';
import isUnique from '@/shared/modules/uniqueFilter';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

export default defineComponent({
  name: 'ModalRuleCheckViolationsContainer',
  components: { ModalRuleCheckViolations },
  computed: {
    visible: {
      get(): boolean {
        return this.$store.state.precisionFarming.applicationMaps.spraying.showRuleViolationModal;
      },
      set(show: boolean): void {
        this.$store.state.precisionFarming.applicationMaps.spraying.showRuleViolationModal = show;
      },
    },
    ...mapState('precisionFarming/applicationMaps/spraying', ['violations']),
    ...mapState('activities/ruleCheck/rules', {
      rules: 'data',
    }),
    violationMessages(): string[] {
      return this.violations
        .map((violation: ActivityRuleViolation) => {
          const rule: Rule = this.rules[violation.ruleId];
          const interpolatableMessage = this.toInterpolatableMessage(rule);
          return this.interpolate(interpolatableMessage, violation);
        })
        .filter(isUnique)
        .filter(notNullOrUndefined);
    },
    featureDismissViolationEnabled(): boolean {
      return !this.$store.getters.currentCompaniesHaveFeatureReadonly(availableFeatures.FEATURE_RULE_CHECK);
    },
  },
  methods: {
    toInterpolatableMessage(rule: Rule, anchor = '%s'): string {
      return rule.parameterNames
        .map((parameterName: string) => `{${parameterName}}`)
        .reduce((previous, current) => previous.replace(anchor, current), rule.longMessage);
    },
    interpolate(interpolatableMessage: string, violation: ActivityRuleViolation): string | undefined {
      const { violationData } = violation;
      return this.$t(interpolatableMessage, {
        culture: violationData.culture ?? '{culture}',
        pesticide: violationData.pesticide ?? '{pesticide}',
        pest: violationData.pest ?? '{pest}',
        ruleAmount: violationData.ruleAmount ?? '{ruleAmount}',
        ruleUnit: violationData.ruleUnit ?? '{ruleUnit}',
        measureAmount: violationData.measureAmount ?? '{measureAmount}',
        measureUnit: violationData.measureUnit ?? '{measureUnit}',
      });
    },
  },
});
