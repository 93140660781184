var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import moment from 'moment';
import router from '@/router';
import { dataToBase64 } from '@/shared/api/rest/requestUtils';
import { getCookie, setCookie } from '@/shared/modules/cookieHelpers';
import { getRestResponseData, mergeRestResponseData } from '@/shared/modules/restApiHelpers';
import { FARMDOK_AUTH_CHECK_EMAIL_VISITED, FARMDOK_AUTH_CURRENT_COMPANIES_AND_PROCESS_ORDER_NAME, FARMDOK_AUTH_REGISTRATION_PROCESS_ACTIVE, } from '../constants';
import { FetchingKey } from './types';
const actions = {
    /**
     * Sets the currentCompanies. Also updates currentProcessOrderName if the current one is out of range.
     *
     * @param dispatch
     * @param commit
     * @param state
     * @param companies
     */
    setCurrentCompanies({ dispatch, commit, state }, companies) {
        commit('setCurrentCompaniesAndProcessOrderName', {
            companies,
            processOrderName: state.currentProcessOrderName,
        });
        dispatch('persistCurrentCompanies');
        dispatch('resetData', null, { root: true });
    },
    /**
     * Updates currentProcessOrderName in the store state, but only if one of the currentCompanies has a process order in this year.
     *
     * @param {function} dispatch
     * @param {function}commit
     * @param {object} getters
     * @param {string} processOrderName
     * @throws RangeError
     */
    setCurrentProcessOrderName({ dispatch, commit, getters }, processOrderName) {
        if (!getters.availableProcessOrderNames.includes(processOrderName)) {
            throw new RangeError(`
            setCurrentProcessOrderName: processOrderName "${processOrderName}"
            out of range: [${getters.availableProcessOrderNames.join(', ')}]
          `);
        }
        commit('setCurrentProcessOrderName', processOrderName);
        dispatch('persistCurrentCompanies');
        dispatch('resetData', null, { root: true });
    },
    /**
     * Use setCurrentCompanies instead #326
     *
     * @deprecated
     */
    selectCompanyById({ dispatch, commit, state }, companyId) {
        const currentCompany = state.companiesById[companyId];
        let currentProcessOrder = null;
        if (Array.isArray(currentCompany.processOrders)) {
            currentCompany.processOrders.some((processOrder) => {
                currentProcessOrder = processOrder;
                if (processOrder.name === state.currentProcessOrderName) {
                    return true;
                }
                return false;
            });
        }
        commit('setCurrentProcessOrder', {
            currentCompany,
            currentProcessOrder,
        });
        dispatch('resetData', null, { root: true });
    },
    subscribe({ commit, dispatch, state }) {
        return __awaiter(this, void 0, void 0, function* () {
            if (state.userInfoLoaded) {
                return null;
            }
            if (!state.loggedIn) {
                if (!state.fetchingInitialAccessToken && state.jwtAuth != null) {
                    commit('startFetching', FetchingKey.FETCHING_INITIAL_ACCESS_TOKEN);
                    const loggedIn = yield state.jwtAuth.initTokenChecking();
                    commit('endFetching', FetchingKey.FETCHING_INITIAL_ACCESS_TOKEN);
                    if (!loggedIn) {
                        dispatch('clearObservers');
                        return null;
                    }
                }
            }
            // check loggedIn again as it could have changed due to initTokenChecking
            if (state.loggedIn && !state.fetchingUserInfo) {
                dispatch('updateUserInfo');
            }
            // eslint-disable-next-line no-promise-executor-return
            return new Promise((resolve) => commit('addObserver', resolve));
        });
    },
    /**
     * @private
     * @param commit
     * @param state
     * @param dispatch
     * @returns {Promise<void>}
     */
    updateUserInfo({ commit, dispatch, state }) {
        return __awaiter(this, void 0, void 0, function* () {
            commit('startFetching', FetchingKey.FETCHING_USER_INFO);
            try {
                yield Promise.all([dispatch('loadCurrentUser'), dispatch('loadUserCompanies')]);
            }
            catch (e) {
                console.error('updateUserInfo failed', e);
                dispatch('logout');
            }
            finally {
                commit('endFetching', FetchingKey.FETCHING_USER_INFO);
                dispatch('clearObservers', { status: 'success' });
            }
            if (!state.user.emailIsConfirmed) {
                const checkEmailVisited = localStorage.getItem(FARMDOK_AUTH_CHECK_EMAIL_VISITED);
                if (checkEmailVisited == null ||
                    moment(checkEmailVisited, 'YYYY-MM-DD').format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')) {
                    router.push({ name: 'register-check-email' }).catch(() => {
                        // #356 ignore route guard errors
                        // https://stackoverflow.com/questions/62512168/redirecting-twice-in-a-single-vue-navigation/62563439#62563439
                    });
                    return;
                }
            }
            if (state.userCompanies.length === 0) {
                router.push({ name: 'no-company' }).catch(() => {
                    // #356 ignore route guard errors
                    // https://stackoverflow.com/questions/62512168/redirecting-twice-in-a-single-vue-navigation/62563439#62563439
                });
            }
        });
    },
    /**
     * Call if user/companies are already loaded but you want to reload them from the server.
     *
     * @return {Promise<void>}
     */
    refreshUserCompanies({ commit, dispatch, state }) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!state.userInfoLoaded) {
                return dispatch('subscribe');
            }
            if (state.fetchingUserInfo || state.fetchingCompanies) {
                // eslint-disable-next-line no-promise-executor-return
                return new Promise((resolve) => commit('addObserver', resolve));
            }
            commit('startFetching', FetchingKey.FETCHING_COMPANIES);
            try {
                yield dispatch('loadUserCompanies');
            }
            catch (error) {
                console.error('refreshUserCompanies failed', error);
                dispatch('logout');
            }
            finally {
                commit('endFetching', FetchingKey.FETCHING_COMPANIES);
                dispatch('clearObservers', { status: 'success' });
            }
            return null;
        });
    },
    loadCurrentUser({ commit, state }) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield axios.get('/admin/rest/user/current');
            if (state.user.id !== data.data.id) {
                throw new Error('loadCurrentUser: id mismatch!');
            }
            commit('setUser', data.data);
        });
    },
    /**
     * Get current companies from cookie. Can also be set by BE.
     * #340 vue_pages needs to be able to overwrite this behaviour.
     *
     * @override
     * @returns {null}
     */
    getCurrentCompanies() {
        return getCookie(FARMDOK_AUTH_CURRENT_COMPANIES_AND_PROCESS_ORDER_NAME);
    },
    loadUserCompanies({ commit, dispatch, state, getters }) {
        return __awaiter(this, void 0, void 0, function* () {
            const filter = ['AND', ['userId', '=', state.user.id], ['active', '=', 1]];
            let data = { data: [] };
            try {
                const response = yield axios.get(`/admin/rest/companyUser?itemsPerPage=5000&filter=${dataToBase64(filter)}`);
                ({ data } = response);
            }
            catch (error) {
                if (error == null ||
                    // @ts-ignore
                    error.response == null ||
                    // @ts-ignore
                    error.response.data == null ||
                    // @ts-ignore
                    error.response.data.errorCode !== 'noCompany') {
                    console.error(error);
                    throw error;
                }
            }
            const userCompanies = [];
            yield Promise.all(data.data.map((userCompany) => __awaiter(this, void 0, void 0, function* () {
                const resolve = ['region'];
                const response = yield axios.get(
                // @ts-ignore
                `/admin/rest/company/${userCompany.companyId}?resolve=${dataToBase64(resolve)}`);
                userCompanies.push(Object.assign(Object.assign({}, userCompany), { company: response.data.data }));
                if (response.data.data.features != null) {
                    commit('features/addFeaturesToCompany', { companyId: response.data.data.id, features: response.data.data.features }, { root: true });
                }
            })));
            commit('setUserCompanies', userCompanies);
            // initialize currentCompanies
            // 1. Try to get from FARMDOK_AUTH_CURRENT_COMPANIES_AND_PROCESS_ORDER_NAME cookie
            // 2. If the cookie is not set try to set the user default company
            // 3. If default company isn't available just use the first company that is available
            const companies = [];
            let processOrderName = moment().format('YYYY');
            const currentCompanies = yield dispatch('getCurrentCompanies');
            if (currentCompanies != null && Array.isArray(currentCompanies.companies)) {
                currentCompanies.companies.forEach((id) => {
                    if (state.companiesById[id] == null) {
                        return;
                    }
                    companies.push(state.companiesById[id]);
                });
                ({ processOrderName } = currentCompanies);
            }
            if (companies.length === 0) {
                // @ts-ignore
                if (state.user.companyId != null && state.companiesById[state.user.companyId] != null) {
                    // @ts-ignore
                    companies.push(state.companiesById[state.user.companyId]);
                    // @ts-ignore
                    const { processOrderId } = state.companiesById[state.user.companyId];
                    if (processOrderId != null && getters.processOrdersById[processOrderId] != null) {
                        processOrderName = getters.processOrdersById[processOrderId].name;
                    }
                }
                else if (userCompanies.length > 0) {
                    companies.push(userCompanies[0].company);
                }
            }
            commit('setCurrentCompaniesAndProcessOrderName', {
                companies,
                processOrderName,
            });
            dispatch('persistCurrentCompanies');
        });
    },
    login({ commit, state, dispatch }, { email, password }) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            localStorage.removeItem(FARMDOK_AUTH_CHECK_EMAIL_VISITED);
            localStorage.removeItem(FARMDOK_AUTH_REGISTRATION_PROCESS_ACTIVE);
            dispatch('resetData', null, { root: true });
            commit('startFetching', FetchingKey.FETCHING_LOGIN);
            const loginResponse = yield ((_a = state.jwtAuth) === null || _a === void 0 ? void 0 : _a.login(email, password));
            commit('endFetching', FetchingKey.FETCHING_LOGIN);
            if ((loginResponse === null || loginResponse === void 0 ? void 0 : loginResponse.status) !== 'success') {
                return loginResponse;
            }
            // #667 reset current companies cookie if SU (super admin) logs in to prevent unwillingingly editing customer companies
            if (loginResponse.data != null && loginResponse.data.access_token != null) {
                // @ts-ignore
                const body = (_b = state.jwtAuth) === null || _b === void 0 ? void 0 : _b.constructor.getDataFromToken(loginResponse.data.access_token);
                if (body != null && body.super_admin === true) {
                    setCookie(FARMDOK_AUTH_CURRENT_COMPANIES_AND_PROCESS_ORDER_NAME, null);
                }
            }
            return dispatch('subscribe');
        });
    },
    loginWithOneTimeToken({ commit, state, dispatch }, { oneTimeToken }) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            localStorage.removeItem(FARMDOK_AUTH_REGISTRATION_PROCESS_ACTIVE);
            dispatch('resetData', null, { root: true });
            commit('startFetching', FetchingKey.FETCHING_LOGIN);
            const loginResponse = yield ((_a = state.jwtAuth) === null || _a === void 0 ? void 0 : _a.loginOneTime(oneTimeToken));
            commit('endFetching', FetchingKey.FETCHING_LOGIN);
            if ((loginResponse === null || loginResponse === void 0 ? void 0 : loginResponse.status) !== 'success') {
                return loginResponse;
            }
            if ((loginResponse === null || loginResponse === void 0 ? void 0 : loginResponse.data) != null && loginResponse.data.access_token != null) {
                // @ts-ignore
                const body = (_b = state.jwtAuth) === null || _b === void 0 ? void 0 : _b.constructor.getDataFromToken(loginResponse.data.access_token);
                if (body != null && body.super_admin === true) {
                    setCookie(FARMDOK_AUTH_CURRENT_COMPANIES_AND_PROCESS_ORDER_NAME, null);
                }
            }
            return dispatch('subscribe');
        });
    },
    logout({ commit, dispatch, state }) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            localStorage.removeItem(FARMDOK_AUTH_CHECK_EMAIL_VISITED);
            localStorage.removeItem(FARMDOK_AUTH_REGISTRATION_PROCESS_ACTIVE);
            commit('startFetching', FetchingKey.FETCHING_LOGIN);
            yield ((_a = state.jwtAuth) === null || _a === void 0 ? void 0 : _a.logout());
            commit('setLoggedOut');
            dispatch('resetData', null, { root: true });
            commit('endFetching', FetchingKey.FETCHING_LOGIN);
        });
    },
    register({ commit, dispatch }, { email, password, regionId, onboarding = null }) {
        return __awaiter(this, void 0, void 0, function* () {
            commit('startFetching', FetchingKey.FETCHING_REGISTER);
            try {
                const response = yield axios.post('/admin/rest/user/register', {
                    version: '2.0',
                    data: {
                        email,
                        password,
                        regionId,
                        onboarding,
                    },
                });
                if (response.data.status !== 'success') {
                    return response;
                }
            }
            catch (error) {
                // @ts-ignore
                if (error == null || error.response == null) {
                    return null;
                }
                // @ts-ignore
                return error.response.data;
            }
            // eslint-disable-next-line no-promise-executor-return
            yield new Promise((resolve) => setTimeout(resolve, 1000));
            const loginResponse = yield dispatch('login', { email, password });
            commit('endFetching', FetchingKey.FETCHING_REGISTER);
            localStorage.setItem(FARMDOK_AUTH_REGISTRATION_PROCESS_ACTIVE, JSON.stringify(true));
            return loginResponse;
        });
    },
    updateAndSyncUser({ state, commit }, data) {
        return __awaiter(this, void 0, void 0, function* () {
            commit('updateUser', data);
            let responseData = null;
            try {
                const response = yield axios.put(`/admin/rest/user/${state.user.id}`, {
                    version: '2.0',
                    data,
                });
                responseData = response.data;
            }
            catch (error) {
                // @ts-ignore
                if (error != null && error.response != null) {
                    // @ts-ignore
                    responseData = error.response.data;
                }
            }
            return responseData;
        });
    },
    /**
     * Use updateAndSyncCompanyById insead #326
     *
     * @param commit
     * @param id
     * @param data
     * @returns {Promise<any|T>}
     */
    updateAndSyncCurrentCompany({ commit }, { id, data }) {
        return __awaiter(this, void 0, void 0, function* () {
            commit('updateCurrentCompany', { id, data });
            let responseData = null;
            try {
                const response = yield axios.put(`/admin/rest/company/${id}`, {
                    version: '2.0',
                    data,
                });
                responseData = response.data;
            }
            catch (error) {
                // @ts-ignore
                if (error != null && error.response != null) {
                    // @ts-ignore
                    responseData = error.response.data;
                }
            }
            return responseData;
        });
    },
    clearObservers({ state, commit }, response) {
        state.observers.forEach((resolve) => resolve(response));
        commit('clearObservers');
    },
    createProcessOrder({ state, getters }, { direction, copyFields = true, sourceProcessOrderName = null }) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!['next', 'previous'].includes(direction)) {
                console.error('createProcessOrder called with invalid direction');
                return { status: 'error' };
            }
            let finalSourceProcessOrderName = state.currentProcessOrderName;
            if (sourceProcessOrderName != null) {
                finalSourceProcessOrderName = sourceProcessOrderName;
            }
            const sourceProcessOrders = state.currentCompanies.map((company) => getters.processOrderByCompanyIdAndNameAndType(company.id, finalSourceProcessOrderName));
            const responsesData = yield Promise.all(sourceProcessOrders.map((sourceProcessOrder) => __awaiter(this, void 0, void 0, function* () {
                try {
                    const { data } = yield axios.post(`/admin/rest/processOrder/create_${direction}`, {
                        version: '2.0',
                        data: {
                            id: sourceProcessOrder.id,
                            copyFields,
                        },
                    });
                    return getRestResponseData(data);
                }
                catch (error) {
                    return getRestResponseData(error);
                }
            })));
            return mergeRestResponseData(...responsesData);
        });
    },
    persistCurrentCompanies({ state, getters }) {
        if (state.currentCompanies.length === 0 || state.currentProcessOrderName == null) {
            setCookie(FARMDOK_AUTH_CURRENT_COMPANIES_AND_PROCESS_ORDER_NAME, null);
            return;
        }
        setCookie(FARMDOK_AUTH_CURRENT_COMPANIES_AND_PROCESS_ORDER_NAME, {
            companies: state.currentCompanies.map((company) => company.id),
            processOrderName: state.currentProcessOrderName,
        }, 
        // @ts-ignore
        60 * 60 * 24 * 30);
        try {
            const requestData = state.currentCompanies.map((company) => ({
                id: company.id,
                processOrderId: getters.processOrderByCompanyIdAndNameAndType(company.id, state.currentProcessOrderName).id,
            }));
            axios.put('/admin/rest/company/', {
                version: '2.0',
                data: requestData,
            });
        }
        catch (error) {
            console.error('Unable to update company processOrderIds', error);
        }
    },
};
export default actions;
