import { UNIT_ID_KG_PER_HA } from '@/products/constants';

import initialStateBaseWorkflow from '../../store/baseWorkflowStore/initialState';
import { ApplicationMapsSprayingState } from './types';

export default function initialState(): ApplicationMapsSprayingState {
  return {
    ...initialStateBaseWorkflow(),
    selectedQuantisationCode: 'medium',
    selectedIndexType: 'DNN_LAI', // overwrite default NDVI

    // uploaded zones
    uploadedZonesByFilename: {},
    showRuleViolationModal: false,
    violations: [],

    multiPolyTimestamps: {
      loaded: {},
      current: {},
    },
    // dosage calculation
    calculation: {
      products: [
        {
          product: { id: '', name: '' },
          pest: { id: '', name: '' },
          amount: 0,
          unit: '',
        },
      ],
      sprayMix: 200,
      material: {
        id: null,
        name: '',
      },
      overwrite: {
        reducedSprayMixPerZone: [],
        reducedVegetationPerZone: [],
      },
      unit: UNIT_ID_KG_PER_HA,
      reduction: 0.1,
    },
    workingMeans: [],
  };
}
