import Vue from 'vue';
import { subscribableStore } from './common';
import { getCropNamePart, getCustomerNamePart, getFieldNamePart } from './utils/getterUtils';
const gettersModule = Object.assign(Object.assign({}, subscribableStore.getters), { kindOfUseTypes: (state) => state.kindOfUseTypes, kindOfUseTypesById: (state) => state.kindOfUseTypes.reduce((kindOfUseTypesById, kindOfUse) => (Object.assign(Object.assign({}, kindOfUseTypesById), { [kindOfUse.id]: kindOfUse })), {}), catchCropVariants: (state) => state.catchCropVariants, catchCropVariantsById: (state) => state.catchCropVariants.reduce((catchCropVariantsById, catchCropVariant) => (Object.assign(Object.assign({}, catchCropVariantsById), { [catchCropVariant.id]: catchCropVariant })), {}), amaCodes: (state) => state.amaCodes, amaCodesById: (state) => state.amaCodes.reduce((amaCodesById, amaCode) => (Object.assign(Object.assign({}, amaCodesById), { [amaCode.id]: amaCode })), {}), gwAreas: (state) => state.gwAreas, gwAreasById: (state) => state.gwAreas.reduce((gwAreasById, gwArea) => (Object.assign(Object.assign({}, gwAreasById), { [gwArea.id]: gwArea })), {}), fieldDisplayName: (state, getters, rootState) => (fieldId) => {
        var _a;
        const field = state.data[fieldId];
        if (!field)
            return null;
        // @ts-ignore
        const customerNamePart = getCustomerNamePart((_a = field.fieldGroup) === null || _a === void 0 ? void 0 : _a.customerId, rootState.customers.data);
        const fieldNamePart = getFieldNamePart(field);
        const cropNamePart = getCropNamePart(field.cropId, rootState.products.crops.data);
        return `${customerNamePart}${fieldNamePart}${cropNamePart}`;
    }, dropdownItem: (state, getters) => (fieldId) => {
        var _a;
        const field = state.data[fieldId];
        if (!field)
            return null;
        return {
            id: field.id,
            name: (_a = getters.fieldDisplayName(field.id)) !== null && _a !== void 0 ? _a : '',
        };
    }, dropdownItems: (state, getters) => (processOrderId) => {
        const fields = state.data;
        return [
            {
                name: Vue.i18n.translate('Felder'),
                id: 'fields',
                items: Object.values(fields)
                    .filter((f) => f.processOrderId === processOrderId)
                    .map((f) => { var _a; return ({ id: f.id, name: (_a = getters.fieldDisplayName(f.id)) !== null && _a !== void 0 ? _a : '' }); }),
                sort: true,
            },
        ];
    }, isUniqueFieldName: (state) => (fieldName) => Object.values(state.data)
        .map((field) => field.name)
        .includes(fieldName) });
export default gettersModule;
