import { Field, Product } from '@/shared/api/rest/models';
import { Data } from '@/shared/mixins/store/types';

export default class FieldService {
  private readonly fields: Data<Field>;

  private readonly findProductById: (id: string) => Product | null;

  constructor(fields: Data<Field>, findProductById: (id: string) => Product | null) {
    this.fields = fields;
    this.findProductById = findProductById;
  }

  public getCropOfField(fieldId: string): Product | null {
    const field = this.fields[fieldId];
    if (!field) return null;

    const { cropId } = field;
    if (!cropId) return null;

    const crop = this.findProductById(cropId);
    return crop;
  }
}
